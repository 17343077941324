import {
    isMultiAdmin,
    isCategories,
    isStore,
    isMultiSellers,
    isGlobalNotifications,
} from "./defaultSettings";

let pages = [
    {
        type: "breakline",
    },
    {
        to: "home",
        icon: "ant-design:home-twotone",
        text: "الصفحة الرئيسية",
        type: "page",
        available: true,
    },

    // {
    //     to: "analytics",
    //     icon: "ic:twotone-analytics",
    //     text: "الاحصائيات",
    //     type: "page",
    //     available: false,
    // },
];

if (isMultiSellers) {
    pages = [
        ...pages,
        { type: "breakline" },
        {
            to: "subject",
            icon: "uis:subject",
            text: "اضافة و تعديل المواد",
            type: "page",
            available: false,
        },
        {
            to: "teacher",
            icon: "ph:chalkboard-teacher-duotone",
            text: "اضافة و تعديل البائعين",
            type: "page",
            available: false,
        },
    ];
}

if (isStore) {
    pages = [
        ...pages,
        { type: "breakline" },
        {
            to: "login_user",
            icon: "ant-design:login-outlined",
            text: "تسجيل دخول كـ طالب",
            type: "page",
            available: false,
        },
        { type: "breakline" },
        {
            to: "product",
            icon: "fluent-mdl2:product-release",
            text: "إضافة وتعديل منتج",
            type: "page",
            available: false,
        },
        {
            to: "shipment",
            icon: "streamline-emojis:package",
            text: "إضافة وتعديل شحنة كتب",
            type: "page",
            available: false,
        },
        // {
        //     to: "shipments",
        //     icon: "fluent-mdl2:product-release",
        //     text: "جدول شحنات",
        //     type: "page",
        //     available: false,
        // },
        // { type: "breakline" },
        {
            to: "products_quantity",
            icon: "streamline:stock",
            text: "تعديل عدد المنتج",
            type: "page",
            available: false,
        },
        {
            to: "manual_order",
            icon: "fluent:border-inside-24-filled",
            text: "إنشاء اوردر يدوي",
            type: "page",
            available: false,
        },
        // { type: "breakline" },
        // {
        //     to: "add_user",
        //     icon: "fa-solid:user-cog",
        //     text: "إضافة وتعديل مستخدم",
        //     type: "page",
        //     available: false,
        // },
        // { type: "breakline" },
        // {
        //     to: "duplicated_orders",
        //     icon: "fluent:table-edit-28-regular",
        //     text: "مراجعة الأوردرات المتكررة",
        //     type: "page",
        //     available: false,
        // },
        // {
        //     to: "invoices",
        //     icon: "fa-solid:file-invoice-dollar",
        //     text: "بحث ومراجعة الفواتير",
        //     type: "page",
        //     available: false,
        // },
        { type: "breakline" },
    ];
    if (isCategories) {
        pages = [
            ...pages,
            {
                to: "categories",
                title: "category",
                icon: "ic:round-category",
                text: "اضافه وتعديل فئات المنتجات",
                type: "page",
                available: false,
            },
        ];
    }
}
if (isGlobalNotifications) {
    pages = [
        ...pages,

        {
            to: "global_notification",
            icon: "ant-design:appstore-add-outlined",
            text: "اضافة وتعديل الاشعارات",
            type: "page",
            available: false,
        },
        {
            type: "breakline",
        },
    ];
}
pages = [
    ...pages,
    { type: "breakline" },
    {
        to: "user_profile",
        icon: "carbon:user-profile",
        text: "ملف المستخدم",
        type: "page",
        available: false,
    },
    { type: "breakline" },
];

if (isMultiAdmin) {
    pages = [
        "page",
        ...pages,
        {
            to: "admins",
            icon: "material-symbols:admin-panel-settings",
            text: "اضافه وتعديل مسؤول",
            type: "page",
            available: false,
        },
        {
            type: "breakline",
        },
    ];
}

pages = [
    ...pages,
    {
        to: "account_requests",
        icon: "fluent-mdl2:add-friend",
        text: "طلبات انشاء حساب منفذ",
        type: "page",
        available: false,
    },
];

pages = [
    ...pages,
    {
        to: "users",
        icon: "ph:users-duotone",
        text: "جدول المستخدمين",
        type: "page",
        available: false,
    },
];

pages = [
    ...pages,
    {
        to: "products",
        icon: "fluent-mdl2:product-variant",
        text: "جدول المنتجات",
        type: "page",
        available: false,
    },
    {
        to: "orders",
        icon: "raphael:cart",
        text: "جدول الأوردرات",
        type: "page",
        available: false,
    },
    {
        to: "order_preparation_groups",
        icon: "fluent-mdl2:product-release",
        text: "جدول مجموعات تجهيز الأوردرات",
        type: "page",
        available: false,
    },
];
// }

export const adminPages = pages;

export const submitTypes = [
    { value: "store", text: "اضافة" },
    { value: "update", text: "تعديل" },
    { value: "delete", text: "حذف" },
];
