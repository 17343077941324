import React, { useEffect, useRef } from "react";
import "./snowfall.css";

const Snowfall = () => {
    const snowContainerRef = useRef(null);

    const createSnowflakesOnce = (num, delay = 50) => {
        let count = 0;
        const interval = setInterval(() => {
            if (!snowContainerRef.current) {
                clearInterval(interval);
                return;
            }

            if (count >= num) {
                clearInterval(interval);
                return;
            }

            const snowflake = document.createElement("div");

            const size = Math.random() * 10 + 5; // Random size between 5px and 15px
            const positionX = Math.random() * window.innerWidth; // Random horizontal position
            const duration = Math.random() * 5 + 5; // Random fall duration (5s to 10s)
            const swayDuration = Math.random() * 2 + 3; // Random sway duration (3s to 5s)

            // Apply styles to the snowflake
            snowflake.classList.add("snowflake");
            snowflake.style.width = `${size}px`;
            snowflake.style.height = `${size}px`;
            snowflake.style.left = `${positionX}px`;
            snowflake.style.animationDuration = `${duration}s, ${swayDuration}s`;

            snowContainerRef.current.appendChild(snowflake);

            count++; // Increment the count
        }, delay);
    };

    const adjustSnowContainerHeight = () => {
        if (snowContainerRef.current) {
            snowContainerRef.current.style.height = `${document.body.scrollHeight}px`;
        }
    };

    useEffect(() => {
        // Set initial container height and adjust on window resize
        adjustSnowContainerHeight();
        window.addEventListener("resize", adjustSnowContainerHeight);

        createSnowflakesOnce(60); // Pass the prop value to control the number of snowflakes

        return () => {
            window.removeEventListener("resize", adjustSnowContainerHeight);
        };
    }, []);

    function removeSnows() {
        if (snowContainerRef.current) {
            snowContainerRef.current.style.transition = "opacity 0.5s ease";
            snowContainerRef.current.style.opacity = "0";
            setTimeout(() => {
                if (snowContainerRef.current) {
                    snowContainerRef.current.classList.add("!hidden");
                }
            }, 500);
        }
    }

    return <div id="snow-container" ref={snowContainerRef} onClick={removeSnows}></div>;
};

export default Snowfall;
