import React, { useEffect, useState } from "react";
import { adminPath } from "../../services/defaultSettings";
import { Link, useParams } from "react-router-dom";
import { printFullDateTime } from "../../utils/time";
import Button from "../../components/ui/Button";
import payment from "../../services/paymentServices";
import { printGovernment, printUnit } from "../../utils/ar";
import http from "../../services/httpServices";
import Table from "../../components/ui/Table";
import auth from "../../services/authServices";
import LoadingIcon from "../../components/ui/LoadingIcon";
import AdminContainer from "../../components/ui/AdminContainer";
import { isObjectEmpty } from "../../utils/objects";
import { baseURL } from "../../config";

const OrderPreparationGroup = () => {
    const [governments, setGovernments] = useState([]);
    const [fetchedData, setFetchedData] = useState({});
    async function getGovernments() {
        const { data } = await http.get("/api/governments");
        setGovernments(data);
    }

    const { id } = useParams();
    const getGroupData = async () => {
        try {
            const token = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(token);
            const { data: response } = await http.post(
                `/api/order_preparation_groups/${id}`,
                {},
                config
            );
            setFetchedData(response);
        } catch (error) {}
    };
    useEffect(() => {
        getGovernments();
        getGroupData();
        // getExistsInvoicesStatuses();
        // getExistsDeliveryStatuses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "اسم المشتري",
            reorder: true,
            selector: (row) =>
                row.user ? (
                    row.user.full_name
                ) : (
                    <span className="text-rose-500">-- لم يتم العثور علي المستخدم --</span>
                ),
            sortable: true,
            sortField: "user_id",
        },
    ];
    columns = [
        ...columns,
        {
            name: "رقم هاتف المشتري",
            reorder: true,
            selector: (row) =>
                row.user ? (
                    <Link
                        className="block px-1 py-1 text-center underline"
                        element="Link"
                        to={`/${adminPath}/panel/user_profile/${row.user.id}`}
                    >
                        {(row.user.phone + "").padStart(11, "0")}
                    </Link>
                ) : (
                    <span className="text-rose-500">-- لم يتم العثور علي المستخدم --</span>
                ),
            sortable: true,
            sortField: "user_id",
        },
        {
            name: "حالة الدفع",
            selector: (row) => (
                <b>
                    <span className={`invoice_status ${row.invoice_status}`}>
                        {row.invoice_status}
                    </span>
                </b>
            ),
            sortable: true,
            sortField: "invocie_status",
        },
    ];

    columns = [
        ...columns,

        {
            name: "وقت الدفع",
            selector: (row) => (row.payment_time ? printFullDateTime(row.payment_time) : ""),
            sortable: true,
            sortField: "payment_time",
        },
        {
            name: "رقم شحنه ارامكس",
            selector: (row) => (row.aramex_shipment_id ? row.aramex_shipment_id : "لا يوجد"),
            sortable: true,
            sortField: "aramex_shipment_id",
        },
        {
            name: "بوليصه ارامكس",
            selector: (row) =>
                row.aramex_label ? (
                    <div className="flex-center-both">
                        <Button
                            color="rose"
                            element="a"
                            href={
                                row.aramex_label.includes("bill_of_ladings")
                                    ? `${baseURL}/${row.aramex_label}`
                                    : row.aramex_label
                            }
                            target="_blank"
                        >
                            البوليصه
                        </Button>
                    </div>
                ) : (
                    "لا توجد"
                ),
            sortable: true,
            sortField: "aramex_label",
        },
        {
            name: "حالة الشحن",
            reorder: true,
            selector: (row) => (
                <b>
                    <span className={`delivery_status ${row.delivery_status}`}>
                        {row.delivery_status}
                    </span>
                </b>
            ),
            sortable: true,
            sortField: "delivery_status",
        },
        {
            name: "آخر تحديث لحالة الشحن (تاريخ الشحن)",
            reorder: true,
            selector: (row) =>
                row.last_updated_delivery_status_date_range
                    ? printFullDateTime(row.last_updated_delivery_status)
                    : "",
            sortable: true,
            sortField: "last_updated_delivery_status",
        },
        {
            name: "وقت عمل طلب التوصيل",
            reorder: true,
            selector: (row) =>
                row.order_delivery_creation_time
                    ? printFullDateTime(row.order_delivery_creation_time)
                    : "",
            sortable: true,
            sortField: "order_delivery_creation_time",
        },
    ];
    columns = [
        ...columns,

        {
            name: "اجمالي عدد المشتريات",
            selector: (row) => row.total_quantity,
            sortable: true,
            sortField: "total_quantity",
        },
    ];
    columns = [
        ...columns,
        {
            name: "وزن الشحنه",
            selector: (row) => row.weight,
            sortable: true,
            sortField: "weight",
        },
        {
            name: "سعر الشحن",
            selector: (row) => row.customer_delivery_price,
            sortable: true,
            sortField: "customer_delivery_price",
        },
    ];

    columns = [
        ...columns,
        {
            name: "اجمالي سعر الأوردر",
            selector: (row) => row.total_price,
            sortable: true,
            sortField: "total_price",
        },
        {
            name: "المشتريات",
            selector: (row) =>
                row.products && row.products.length ? (
                    <div className="flex flex-col space-y-2">
                        {row.products.map((product, index) => (
                            <span key={index}>
                                {product.pivot.quantity} : {product.name}
                            </span>
                        ))}
                    </div>
                ) : (
                    ""
                ),
            sortable: false,
            // sortField: "total_price",
        },
    ];
    columns = [
        ...columns,
        {
            name: "رقم الفاتورة",
            selector: (row) => (
                <div>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={payment.getInvoiceUrl({
                            invoice_id: row.provider_invoice_id,
                            invoice_key: row.provider_invoice_key,
                            invoice_provider: row.invoice_provider,
                        })}
                        className="underline"
                    >
                        {row.provider_invoice_id}
                    </a>
                </div>
            ),
            sortable: true,
            sortField: "provider_invoice_id",
        },

        {
            name: "kh_salesOrderNumber",
            selector: (row) => row.kh_salesOrderNumber,
            sortable: true,
            sortField: "kh_salesOrderNumber",
        },
    ];

    columns = [
        ...columns,

        {
            name: "رقم الهاتف البديل",
            selector: (row) => (row.user ? (row.user.father_phone + "").padStart(11, "0") : ""),
            sortable: true,
            sortField: "father_phone",
        },
        {
            name: "المحافظة",
            selector: (row) => printGovernment(governments, row.government_id),
            sortable: true,
            sortField: "government_id",
        },
        {
            name: "المركز",
            selector: (row) => row.city,
            reorder: true,
        },
        {
            name: "العنوان",
            selector: (row) => row.address,
            reorder: true,
        },
        {
            name: "تاريخ عمل الاوردر",
            selector: (row) => printFullDateTime(row.created_at),
            reorder: true,
            sortable: true,
            sortField: "created_at",
        },
    ];
    return (
        <>
            <AdminContainer>
                {!isObjectEmpty(fetchedData) ? (
                    <div className="w-full space-y-6">
                        <Link to="../" className="underline">
                            العودة للخلف
                        </Link>
                        <div className="bg-teal-800 text-white dark:bg-cyan-900 smooth shadow-md rounded p-6 mb-6 text-center">
                            <h2 className="text-2xl font-bold mb-6">
                                {fetchedData.order_preparation_group.name}
                            </h2>
                            <h2 className="text-h3 font-bold mb-6">
                                {printFullDateTime(fetchedData.order_preparation_group.created_at)}
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
                                <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth flex-center-both text-center flex-col space-y-2">
                                    <h3 className="text-h3 font-semibold">عدد البوليصات</h3>
                                    <span className="text-2xl font-bold font-h1">
                                        {printUnit(fetchedData.files.length, "بوليصه")}
                                    </span>
                                </div>
                                <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth flex-center-both text-center flex-col space-y-2">
                                    <h3 className="text-h3 font-semibold">عدد الاوردرات</h3>
                                    <span className="text-2xl font-bold font-h1">
                                        {printUnit(fetchedData.orders.length, "اوردر")}
                                    </span>
                                </div>
                                <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth flex-center-both text-center flex-col space-y-2">
                                    <h3 className="text-h3 font-semibold">عدد المنتجات</h3>
                                    <span className="text-2xl font-bold font-h1">
                                        {printUnit(
                                            fetchedData.order_preparation_group.products_quantity,
                                            "منتج"
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="bg-teal-900 py-0.5 w-[80%] mx-auto my-5"></div>
                            <h2 className="text-2xl font-bold mb-6">
                                بوليصات شحن مجموعة الاوردرات
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                                {fetchedData.files.map((file, index) => (
                                    <a
                                        href={`${baseURL}/${file.file_name}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="p-4 border bg-cyan-700 dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth flex-center-both text-center flex-col hover-shadow">
                                            <h3 className="text-lg font-semibold en">
                                                {file.notes}
                                            </h3>
                                            <span className="text-2xl font-bold">
                                                {printUnit(file.quantity, "اوردر")}
                                            </span>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                        <Table columns={columns} data={fetchedData.orders} />
                    </div>
                ) : (
                    <div>
                        <LoadingIcon className={"font-h1"} />
                    </div>
                )}
            </AdminContainer>
        </>
    );
};

export default OrderPreparationGroup;
