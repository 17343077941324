import React, { useEffect, useState } from "react";
import "./animate.css"; // Import the CSS file containing the styles
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { adminPath } from "../../services/defaultSettings";

const AnimateHeader = () => {
    const location = useLocation();

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (location.pathname.includes(adminPath)) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [location.pathname]);
    return (
        <div className="hero text-center">
            <div className="hero__title">
                <h1 className="font-big before-box relative">
                    <span className="font-w-bold relative smooth">
                        {!isAdmin ? (
                            <Header
                                title="نورت"
                                coloredTitle="صلاح ستور"
                                textColor="text-moGreenV2-800"
                            />
                        ) : (
                            <Header
                                title="لوحة تحكم"
                                coloredTitle="صلاح ستور"
                                textColor="text-moGreenV2-800"
                            />
                        )}
                    </span>
                </h1>
            </div>
            <div className="cube"></div>
            <div className="cube"></div>
            <div className="cube"></div>
            <div className="cube"></div>
            <div className="cube"></div>
            <div className="cube"></div>
            <div className="cube"></div>
            <div className="cube"></div>
        </div>
    );
};

export default AnimateHeader;
