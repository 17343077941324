import Container from "../../components/ui/Container";
import HeaderCardsSection from "../../components/ui/HeaderCardsSection";
import { isMultiYear } from "../../services/defaultSettings";
import { ProductSection } from "../Products/ProductSection";
import { handleInputChange } from "../../services/formServices";
import InputField from "../../components/form/elements/InputField";
import { semester, years } from "../../services/yearSevices";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";

const YearSection = () => {
    const { user } = useContext(AuthContext);
    const [filterData, setFilterData] = useState({
        year: 3,
        semester_id: 1,
    });

    const toUseYears = years;
    const toUseSemesters = semester;

    useEffect(() => {
        if (user && user.year) {
            setFilterData({ ...filterData, year: user.year });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, user.year]);

    return (
        <section className="min-h-[80vh] relative overflow-hidden my-10">
            <Container className="h-full relative z-10 font-the-sans pt-32 md:pt-0 pb-20 md:pb-0 space-y-4 flex flex-col items-center justify-center">
                <div className="flex flex-col items-center justify-center gap-[40px] lg:gap-15">
                    <div className="flex flex-col items-center justify-center h-fit gap-10">
                        <HeaderCardsSection
                            title="كٌــــتبك"
                            coloredTitle="بالسنة الدراسية"
                            colorClass="text-moGreenV2-800"
                        />
                        <div className="flex-center-both flex-col sm:flex-row  px-10 py-4 sm:py-8 rounded-2xl space-y-4 sm:space-y-0 sm:gap-10 bg-slate-300 dark:bg-slate-800 smooth ">
                            {isMultiYear ? (
                                <InputField
                                    onChange={handleInputChange}
                                    data={filterData}
                                    setData={setFilterData}
                                    type="select"
                                    options={toUseYears}
                                    id="year"
                                    placeholder="اختر الصف الدراسي"
                                    // errors={errors}
                                />
                            ) : (
                                ""
                            )}
                            {parseInt(filterData.year) != 3 ? (
                                <InputField
                                    onChange={handleInputChange}
                                    data={filterData}
                                    setData={setFilterData}
                                    type="select"
                                    options={toUseSemesters}
                                    id="semester_id"
                                    placeholder="اختر الترم"
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <ProductSection
                        api={`/api/user/products?product_type=book&year=${filterData.year}&semester=${filterData.semester_id}&limit=9`}
                        isDecoreRight={false}
                        isShowMore={true}
                        redirect_link="/years"
                        CardColor="bg-[#ECF8F8] dark:bg-[#3D5A5B]"
                        product_type="book"
                    />
                    {/* { here are svg decore} */}
                </div>
            </Container>
        </section>
    );
};

export default YearSection;
