import React from "react";
// import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation, Navigate, Outlet } from "react-router-dom";

// import { CourseProvider } from "./context/CourseContext";

// pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Logout from "./pages/Logout";
import NotFound from "./pages/NotFound";

//ui
// import AnimatingPage from "./middleware/AnimatingPage";

//middlewares
import ProtectedRoute from "./middleware/ProtectedRoute";
import OnlyGuestRoute from "./middleware/OnlyGuestRoute";
import User from "./pages/User";

import AdminLogin from "./pages/AdminLogin";
import AdminLogout from "./pages/AdminLogout";
import NotAdmin from "./middleware/NotAdmin";
import OnlyAdmin from "./middleware/OnlyAdmin";
import AdminPanel from "./pages/AdminPanel";
import ManualSubscription from "./pages/admin/ManualSubscription";
import { adminPath, outletPath, isAccountCreationRequest } from "./services/defaultSettings";

import InvoicesTable from "./pages/admin/InvoicesTable";

import CategoryEditing from "./pages/admin/CategoryEditing";
import TeacherEditing from "./pages/admin/TeacherEditing";
import AdminHome from "./pages/admin/AdminHome";
import LoginAsUser from "./pages/admin/LoginAsUser";
import SearchOrder from "./pages/admin/SearchOrder";
import ProductEditing from "./pages/admin/ProductEditing";
import ShipmentEditing from "./pages/admin/ShipmentEdititing";
import ShipmentsTable from "./pages/admin/ShipmentsTable";
import ProductsQuantity from "./pages/admin/ProductsQuantity";
import ManualOrder from "./pages/admin/ManualOrder";
import UserEditing from "./pages/admin/UserEditing";
import OrderDatesTable from "./pages/admin/OrderDatesTable";
import DuplicatedOrdersTable from "./pages/admin/DuplicatedOrdersTable";
import ProductsStatisics from "./pages/admin/ProductStatisics";
import ProductsTable from "./pages/admin/ProductsTable";
import OrdersTable from "./pages/admin/OrdersTable";
import { Cart } from "./pages/Cart";
import BestSellings from "./pages/BestSellings";
import FilterByYear from "./pages/FilterByYear";
import Products from "./pages/Products";
import { ProductProvider } from "./context/ProductProvider";
import Product from "./pages/Product";
import ProductHome from "./pages/product/ProductHome";
import UserHomeProfile from "./pages/user/UserHomeProfile";
import MyOrders from "./pages/user/MyOrders";
import MyAddress from "./pages/user/MyAddress";
import OrderInvoice from "./pages/product/OrderInvoice";
import CartHasProducts from "./middleware/CartHasProducts";
import CategoryOrderEditing from "./pages/admin/CategoryOrderEditing";
import SubjectEditing from "./pages/admin/SubjectEditing";
import FullAddressEntered from "./middleware/FullAddressEntered";
import StoreRegister from "./pages/StoreRegister";
import MegaBuyer from "./pages/MegaBuyer";
import OutletLogin from "./pages/OutletLogin";
import NotBuyer from "./middleware/NotBuyer";
import MegaBuyerHome from "./pages/MegaBuyerHome";
import AccountCreationStatus from "./pages/AccountCreationStatus";
import OutletRoute from "./middleware/OutletRoute";
import OrderOutlet from "./pages/OrderOutlet";
import OutletLogout from "./pages/OutletLogout";
import { CartOutlet } from "./pages/CartOutlet";
import AccountCreationRequestsTable from "./pages/admin/AccountCreationRequestsTable";
import UserProfileForm from "./pages/admin/UserProfileForm";
import UsersTable from "./pages/admin/UsersTable";
import ManageAdmins from "./pages/admin/ManageAdmins";
import UserProfile from "./pages/admin/UserProfile";
import OrderPreparationGroupsHome from "./pages/admin/OrderPreparationGroupsHome";
import OrderPreparationGroup from "./pages/admin/OrderPreparationGroup";
import GlobalNotificationsEditing from "./pages/admin/GlobalNotificationsEditing";

const Pathes = () => {
    const location = useLocation();

    let routeKey = location.pathname.split("/");
    routeKey = routeKey[0] + "/" + routeKey[1] + "/" + routeKey[2];

    return (
        <>
            <Routes location={location} key={routeKey}>
                <Route path="/" exact element={<Home />} />
                <Route path="/home" exact element={<Home />} />
                <Route path="/bestsellings" exact element={<BestSellings />} />
                <Route path="/years" exact element={<FilterByYear />} />
                <Route path="/products" exact element={<Products />} />

                <Route
                    path="/cart"
                    exact
                    element={
                        <ProtectedRoute>
                            <Cart />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={`${outletPath}/cart`}
                    exact
                    element={
                        <OutletRoute>
                            <CartOutlet />
                        </OutletRoute>
                    }
                />
                <Route
                    path={`${outletPath}/request_order`}
                    exact
                    element={
                        <OutletRoute>
                            <OrderOutlet />
                        </OutletRoute>
                    }
                />

                <Route
                    path="/order_invoice"
                    exact
                    element={
                        <ProtectedRoute>
                            <CartHasProducts>
                                <FullAddressEntered>
                                    <OrderInvoice />
                                </FullAddressEntered>
                            </CartHasProducts>
                        </ProtectedRoute>
                    }
                />

                {/* Pages per product */}
                <Route
                    path="/product/:id"
                    element={
                        <ProductProvider>
                            <Product />
                        </ProductProvider>
                    }
                >
                    <Route index element={<ProductHome />} />
                    <Route path="home" element={<ProductHome />} />
                </Route>

                <Route
                    path="/me/user/"
                    exact
                    element={
                        <ProtectedRoute>
                            <User />
                        </ProtectedRoute>
                    }
                >
                    <Route index element={<UserHomeProfile />} />
                    <Route path="home/" element={<UserHomeProfile />} />
                    <Route path="my-orders" element={<MyOrders />} />
                    <Route path="my-address" element={<MyAddress />} />

                    {/* <Route path="courses" element={<UserCourses />} />
                    <Route path="change_password" element={<ChangePassword />} />
                    <Route path="login_data" element={<LoginData />} />
                    <Route path="video_views" element={<VideoViews />} />
                    <Route path="invoices" element={<Invoices />} />
                    <Route path="subscriptions" element={<Subscriptions />} />
                    <Route path="exam_results" element={<ExamResults />} />
                    <Route path="hm_results" element={<HmResults />} />
                    <Route path="video_quizes" element={<VideoQuizResults />} />
                    <Route path="exam_results/:exam_result_id" element={<ExamResult />} />
                    <Route path="notifications" element={<Navigate replace to="../" />} /> */}
                    {/* <Route path="notifications" element={<VideoViews />} /> */}
                </Route>

                <Route
                    path="/login"
                    exact
                    element={
                        <OnlyGuestRoute>
                            <Login />
                        </OnlyGuestRoute>
                    }
                />

                <Route
                    path="/register"
                    exact
                    element={
                        <OnlyGuestRoute>
                            <Register />
                        </OnlyGuestRoute>
                    }
                />
                <Route
                    path="/buyer-register"
                    exact
                    element={
                        <OnlyGuestRoute>
                            <StoreRegister />
                        </OnlyGuestRoute>
                    }
                />
                {isAccountCreationRequest ? (
                    <Route
                        path="/account_creation_status"
                        exact
                        element={
                            <OnlyGuestRoute>
                                <AccountCreationStatus />
                            </OnlyGuestRoute>
                        }
                    />
                ) : (
                    ""
                )}
                <Route
                    path="/logout"
                    exact
                    element={
                        <ProtectedRoute>
                            <Logout />
                        </ProtectedRoute>
                    }
                />

                {/* <Route
                    path="/course/:id"
                    element={
                        <CourseProvider>
                            <Course />
                        </CourseProvider>
                    }
                >
                    <Route index element={<CourseHome />} />
                    <Route path="home" element={<CourseHome />} />
                    <Route
                        path="sections/:section_id"
                        element={
                            <ProtectedRoute>
                                <Outlet />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path="video/:video_id"
                            element={
                                <ProtectedRoute>
                                    <Video key={location.pathname} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="book/:book_id"
                            element={
                                <ProtectedRoute>
                                    <Book />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="exam/:exam_id"
                            element={
                                <ProtectedRoute>
                                    <ExamProvider>
                                        <Exam />
                                    </ExamProvider>
                                </ProtectedRoute>
                            }
                        >
                            <Route index element={<ExamHome />} />
                            <Route path="questions/:question_id" element={<Question />} />
                            <Route path="questions" element={<Navigate replace to="" />} />
                            <Route path="*" element={<Navigate replace to="" />} />
                        </Route>
                    </Route>
                    <Route
                        path="subscription_token_card"
                        element={<CenterSubscriptionTokenCard />}
                    />
                    <Route path="migrate_subscription" element={<MigrateApiCourseSubscription />} />
                    <Route path="subscribe">
                        {isManualPayment ? (
                            <>
                                <Route index element={<PaymentInfoPage />} />
                                <Route path="*" element={<Navigate replace to="" />} />
                            </>
                        ) : (
                            <>
                                <Route
                                    path="previous_invoices"
                                    element={
                                        <ProtectedRoute>
                                            <SubscriptionPreviousInvoices />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="invoice"
                                    element={
                                        <ProtectedRoute>
                                            <SubscriptionInvoice />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    index
                                    element={<Navigate replace to="previous_invoices" />}
                                />
                                <Route
                                    path="*"
                                    element={<Navigate replace to="previous_invoices" />}
                                />
                            </>
                        )}
                    </Route>
                </Route>
                <Route
                    path="/subject/:id"
                    element={
                        <>
                            <SubjectProvider>
                                <Subject />
                            </SubjectProvider>
                        </>
                    }
                >
                    <Route index element={<SubjectHome />} />
                    <Route path="home" element={<SubjectHome />} />
                </Route>

                <Route
                    path="/teacher/:id"
                    element={
                        <>
                            <TeacherProvider>
                                <Teacher />
                            </TeacherProvider>
                        </>
                    }
                >
                    <Route index element={<TeacherHome />} />
                    <Route path="home" element={<TeacherHome />} />
                </Route> */}

                <Route path={outletPath}>
                    <Route
                        path="login"
                        exact
                        element={
                            <NotBuyer>
                                <OutletLogin />
                            </NotBuyer>
                        }
                    />
                    <Route
                        path="outlet"
                        element={
                            <OutletRoute>
                                <MegaBuyer />
                            </OutletRoute>
                        }
                    >
                        <Route index exact element={<MegaBuyerHome />} />
                        <Route path="home" exact element={<MegaBuyerHome />} />
                        <Route path="*" element={<Navigate replace to="home" />} />
                    </Route>
                    <Route path="logout" exact element={<OutletLogout />} />

                    <Route index element={<Navigate replace to="login" />} />
                    <Route path="*" element={<Navigate replace to="login" />} />
                </Route>
                <Route path={adminPath}>
                    <Route
                        path="login"
                        exact
                        element={
                            <NotAdmin>
                                <AdminLogin />
                            </NotAdmin>
                        }
                    />

                    <Route
                        path="panel"
                        element={
                            <OnlyAdmin>
                                <AdminPanel />
                            </OnlyAdmin>
                        }
                    >
                        <Route index exact element={<ManualSubscription />} />
                        <Route path="home" exact element={<AdminHome />} />
                        <Route path="subject" exact element={<SubjectEditing />} />
                        <Route path="teacher" exact element={<TeacherEditing />} />
                        <Route
                            path="global_notification"
                            exact
                            element={<GlobalNotificationsEditing />}
                        />
                        <Route path="login_user" exact element={<LoginAsUser />} />
                        {/* <Route path="search_order" exact element={<SearchOrder />} /> */}
                        <Route path="product" exact element={<ProductEditing />} />
                        <Route path="shipment" exact element={<ShipmentEditing />} />
                        <Route path="products_quantity" exact element={<ProductsQuantity />} />
                        <Route path="categories" exact element={<CategoryOrderEditing />} />
                        <Route path="manual_order" exact element={<ManualOrder />} />
                        {/* <Route path="duplicated_orders" exact element={<DuplicatedOrdersTable />} /> */}
                        {/* <Route path="product_statistics" exact element={<ProductsStatisics />} /> */}
                        <Route path="user_profile">
                            <Route index exact element={<UserProfileForm />} />
                            <Route path=":id" exact element={<UserProfile />} />
                        </Route>
                        {/* <Route path="add_user" exact element={<UserEditing />} /> */}
                        {/* <Route path="add_user" exact element={<AddUser />} /> */}
                        <Route path="admins" exact element={<ManageAdmins />} />
                        <Route
                            path="account_requests"
                            exact
                            element={<AccountCreationRequestsTable />}
                        />
                        <Route path="users" exact element={<UsersTable />} />
                        <Route path="products" exact element={<ProductsTable />} />
                        <Route path="orders" exact element={<OrdersTable />} />
                        <Route path="order_preparation_groups" exact>
                            <Route index element={<OrderPreparationGroupsHome />} />
                            <Route path=":id" element={<OrderPreparationGroup />} />
                        </Route>
                        {/* <Route path="shipments" exact element={<ShipmentsTable />} /> */}

                        {/* <Route path="coupon" exact element={<CouponEditing />} /> */}

                        <Route path="logout" exact element={<AdminLogout />} />
                        <Route path="*" element={<Navigate replace to="home" />} />
                    </Route>
                    <Route index element={<Navigate replace to="login" />} />
                    <Route path="*" element={<Navigate replace to="login" />} />
                </Route>
                <Route path="404-not-found" element={<NotFound />} />
                <Route path="/index.php" element={<Navigate replace to="/home" />} />
                <Route path="/register.php" element={<Navigate replace to="/register" />} />
                <Route path="/login.php" element={<Navigate replace to="/login" />} />
                <Route path="/courses.php" element={<Navigate replace to="/home" />} />
                <Route path="*" element={<Navigate replace to="/404-not-found" />} />
            </Routes>
        </>
        // </AnimatePresence>
    );
};

export default Pathes;
