import React, { useEffect, useState } from "react";
import AdminContainer from "../../components/ui/AdminContainer";
import http from "../../services/httpServices";
import auth from "../../services/authServices";
import LoadingIcon from "../../components/ui/LoadingIcon";
import { printUnit } from "../../utils/ar";
import { isObjectEmpty } from "../../utils/objects";
import CenterIcon from "../../components/ui/CenterIcon";
import modal from "../../services/modalServices";
import InputField from "../../components/form/elements/InputField";
import Form from "../../components/form/elements/Form";
import { handleInputChange } from "../../services/formServices";
import Button from "../../components/ui/Button";
import OrderPreparationGroupsTable from "./OrderPreparationGroupsTable";
import modal2 from "../../services/modal2Services";

const OrderPreparationGroupsHome = () => {
    const [data, setData] = useState({
        orders_id: [],
    });

    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [fetchedData, setFetchedData] = useState({});
    const [sortedDetailsOfFetchedData, setSortedDetailsOfFetchedData] = useState([]);

    const getGroupsData = async () => {
        try {
            const token = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(token);
            const { data: response } = await http.get(
                `/api/order_preparation_groups/get_groups`,
                config
            );

            const sortedDetails = Object.keys(response.ungrouped_orders_details).sort((a, b) => {
                const detailsA = response.ungrouped_orders_details[a];
                const detailsB = response.ungrouped_orders_details[b];

                // First sort by delivery_provider alphabetically
                const providerComparison = detailsA.delivery_provider.localeCompare(
                    detailsB.delivery_provider
                );
                if (providerComparison !== 0) {
                    return providerComparison;
                }

                // Then sort by products_sku_with_quantity length
                return (
                    detailsA.products_sku_with_quantity.length -
                    detailsB.products_sku_with_quantity.length
                );
            });
            setFetchedData(response);
            setSortedDetailsOfFetchedData(sortedDetails);
        } catch (error) {}
    };
    const handleGroupingOrders = async (is_selected_orders_only = false) => {
        setIsLoading(true);
        try {
            let orders_id = [];
            if (is_selected_orders_only) {
                orders_id = data.orders_id.join(",");
            }
            const token = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(token);
            const { data: response } = await http.post(
                `/api/order_preparation_groups/group_orders`,
                is_selected_orders_only
                    ? { orders_id, is_selected_orders_only: is_selected_orders_only ? 1 : 0 }
                    : {},
                config
            );
            setFetchedData({
                ...response,
                groups: [...response.new_groups, ...fetchedData.groups],
            });
            setData({ orders_id: [] });
            modal.message({
                title: "تم توزيع الاوردرات بنجاح",
                text: "تم توزيع الاوردرات بنجاح",
            });
            // setFetchedData(response);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            modal.message({
                title: "حدث خطأ",
                text: error?.response?.data?.message || "حدث خطأ",
                icon: "error",
            });
        }
    };

    useEffect(() => {
        getGroupsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminContainer>
            {!isObjectEmpty(fetchedData) ? (
                <div className="w-full space-y-6">
                    <div className="bg-cyan-800 text-white dark:bg-cyan-900 smooth shadow-md rounded p-6 mb-6 text-center space-y-5">
                        {/* <h2 className="text-2xl font-bold mb-6">بيانات واحصائيات المقبولين</h2> */}
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            <div
                                className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth flex-center-both bg-rose-500 hover-shadow cursor-pointer flex-col"
                                // onClick={() => handleGroupingOrders}
                                onClick={() => {
                                    modal.message({
                                        title: "تأكيد",
                                        text: "هل أنت متأكد أنك تريد توزيع كل الاوردرات الغير موزعة؟",
                                        icon: "warning",
                                        buttons: {
                                            confirm: "نعم",
                                            cancel: "لا",
                                        },
                                        callback: (e) =>
                                            e &&
                                            e !== "cancel" &&
                                            modal.message({
                                                title: "تأكيد مره تانية",
                                                text: "هل انت متأكد انك متأكد انك متأكد من توزيع كل الأوردرات ؟؟؟؟؟",
                                                icon: "warning",
                                                buttons: {
                                                    confirm: "نعم",
                                                    cancel: "لا",
                                                },
                                                callback: (e) =>
                                                    e &&
                                                    e !== "cancel" &&
                                                    modal2.message({
                                                        title: "هل انت متأكد؟",
                                                        input: "text",
                                                        inputLabel:
                                                            "اكتب كلمة 'confirm' لتأكيد توزيع الأوردرات كلها !",
                                                        inputValidator: (value) => {
                                                            if (!value || value !== "confirm") {
                                                                return "غير صحيحه !";
                                                            }
                                                        },
                                                        icon: "warning",
                                                        confirmButtonText: "تنفيذ",
                                                        cancelButtonText: "إلغاء",
                                                        showCancelButton: true,
                                                        callback: async (e) => {
                                                            if (e.isConfirmed) {
                                                                handleGroupingOrders();
                                                            }
                                                        },
                                                    }),
                                            }),
                                    });
                                }}
                            >
                                <span className="font-h1">
                                    <CenterIcon icon="fluent-mdl2:product-release" />
                                </span>
                                <h3 className="text-lg font-semibold">
                                    وزع <span className="underline">كل</span> الاوردرات اللي مش
                                    متوزعه!
                                </h3>
                            </div>
                            <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth flex-center-both text-center flex-col">
                                <h3 className="text-lg font-semibold">
                                    الاوردرات الغير موزعه حتى الآن
                                </h3>
                                <span className="text-2xl font-bold">
                                    {fetchedData.ungrouped_orders_count}
                                </span>
                            </div>
                            <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth flex-center-both text-center flex-col">
                                <h3 className="text-lg font-semibold">
                                    الاوردرات الغير موجوده في اي بوليصه حتى الآن
                                </h3>
                                <span className="text-2xl font-bold">
                                    {fetchedData.unfiled_orders_count}
                                </span>
                            </div>
                        </div>
                        {fetchedData.ungrouped_orders_count > 0 ? (
                            <div className="font-w-bold bg-green-500 rounded-md space-y-5 p-5">
                                <div className="">
                                    عاوز توزع اوردرات معينه بس خاصه ب كتاب او اكتر او عدد معين من
                                    الكتب ؟
                                </div>
                                <Form className="">
                                    <InputField
                                        id="orders_id"
                                        name="orders_id"
                                        type="radio"
                                        multiple={true}
                                        options={sortedDetailsOfFetchedData.map((key, index) => {
                                            return {
                                                text: (
                                                    <div className="flex-col flex-center-both space-y-2 bg-slate-900 bg-opacity-50 p-4 rounded-md">
                                                        <div className="flex space-x-2 flex-center-both justify-between">
                                                            <span className="font-w-bold">
                                                                عدد الاوردرات :{" "}
                                                            </span>
                                                            <span className="text-sm">
                                                                {printUnit(
                                                                    fetchedData
                                                                        .ungrouped_orders_details[
                                                                        key
                                                                    ].orders.length,
                                                                    "اوردر"
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="flex flex-center-both">
                                                            <span className="font-w-bold">
                                                                شركه الشحن :{" "}
                                                            </span>
                                                            <div className="text-sm flex-col flex-center-both space-y-1">
                                                                {fetchedData
                                                                    .ungrouped_orders_details[key][
                                                                    "delivery_provider"
                                                                ] === "self_delivery"
                                                                    ? "توصيل ذاتي من صلاح ستور (الجيزة)"
                                                                    : "Aramex"}
                                                            </div>
                                                        </div>
                                                        <div className="flex space-y-2 flex-col flex-center-both">
                                                            <span className="font-w-bold">
                                                                الكتب :{" "}
                                                            </span>
                                                            <div className="text-sm flex-col flex-center-both space-y-1">
                                                                {fetchedData.ungrouped_orders_details[
                                                                    key
                                                                ].products_sku_with_quantity.map(
                                                                    (book) => {
                                                                        return (
                                                                            <div
                                                                                key={book}
                                                                                className="text-xs"
                                                                            >
                                                                                {book}
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ),
                                                value: fetchedData.ungrouped_orders_details[
                                                    key
                                                ].orders.join(","),
                                            };
                                        })}
                                        onChange={handleInputChange}
                                        data={data}
                                        setData={setData}
                                        errors={errors}
                                    />
                                </Form>
                                <Button
                                    color="rose"
                                    onClick={() => handleGroupingOrders(true)}
                                    isLoading={isLoading}
                                >
                                    توزيع الاوردرات المحدده
                                </Button>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <OrderPreparationGroupsTable
                        fetchedData={fetchedData}
                        setFetchedData={setFetchedData}
                    />
                </div>
            ) : (
                <div>
                    <LoadingIcon className={"font-h1"} />
                </div>
            )}
        </AdminContainer>
    );
};

export default OrderPreparationGroupsHome;
