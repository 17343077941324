import Container from "../../components/ui/Container";
import StoreCta from "../../assets/storeCta.svg";
import Button from "../../components/ui/Button";
import CenterIcon from "../../components/ui/CenterIcon";
const MegaBuyerSection = () => {
    return (
        <section className="min-h-[80vh] relative overflow-hidden my-10">
            <Container className="h-full mx-4 md:mx-8 relative z-10 border border-solid border-black bg-butterMilk-50 rounded-3xl py-16 px-5 md:px-14 font-the-sans space-y-4 flex flex-col gap-10 lg:gap-0 lg:flex-row-reverse items-center justify-center">
                <div className="basis-full lg:basis-1/2 flex-center-both">
                    <img src={StoreCta} alt="store-cta" className="object-cover" />
                </div>
                <div className="basis-full lg:basis-1/2 space-y-10 px-0 md:px-10 flex justify-center  flex-col items-center lg:items-start">
                    <h1 className="font-big font-bold font-ge text-center md:text-right">
                        نفسك تشاركنا <span className="text-moGreenV2-700">الرحلة !</span>
                    </h1>
                    <div className="flex space-y-10 flex-col justify-center lg:justify-start items-center lg:items-start">
                        <p className="w-full lg:w-3/4 font-ge text-center lg:text-right text-lg lg:text-2xl">
                            لو انت <span className="px-1 font-w-bold">منفذ بيع</span> دلوقتي صلاح
                            ستور بتديلك الفرصه انك تشاركها الرحلة التعليمية للمرحلة الثانوية تقدر
                            بخطوات سهلة تسجل كمنفذ بيع وتطلب الكمية الي محتاجها من منتجاتنا ..{" "}
                        </p>
                        <Button
                            className="flex-center-both font-ge w-fit gap-3 py-3 md:py-4 px-4 md:px-8 text-black font-w-bold text-xl  bg-[#FF9260] rounded-lg hover:shadow-lg border-none"
                            element="Link"
                            to="/buyer-register"
                        >
                            سجل وأطلب دلوقتي
                            <CenterIcon icon="lgi:storefront-check" />
                        </Button>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default MegaBuyerSection;
