import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import { useLocation } from "react-router-dom";
import { adminPath, isWinterComing } from "../../../services/defaultSettings";
import CenterIcon from "../../../components/ui/CenterIcon";
import bg from "../../../assets/bg.png";
import { ReactComponent as SnowTopFrame } from "../../../assets/events-assets/snowTopFrame.svg";
import bgWinter from "../../../assets/events-assets/Snow_Flakes.png";

const GlobalNotifications = () => {
    const { token } = useContext(AuthContext);

    const [notifications, setNotifications] = useState([]);

    const [isHidden, setIsHidden] = useState(false);

    const [className, setClassName] = useState("");

    const getNotifications = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);

        const { data } = await http.get(`/api/fetch_global_notification`, config);
        setNotifications(data);
    };

    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes(adminPath)) {
            setClassName(" !hidden");
        } else {
            setClassName("");
        }
    }, [location]);

    useEffect(() => {
        if (token) {
            getNotifications();
        }
    }, [token]);

    return (
        <>
            {notifications.length > 0 ? (
                <div
                    className={`absolute w-full top-[6rem] right-0 left-0 px-2 py-2 ${className} ${
                        isHidden ? "!hidden" : ""
                    }`}
                >
                    <div className="relative rounded-md bg-moGreenV2-800 dark:bg-goBlue-600 smooth text-butterMilk-100 p-5 text-center">
                        {isWinterComing && (
                            <SnowTopFrame className="absolute brightness-95 max-w-lg -top-7 z-50 left-0 right-0 mx-auto " />
                        )}
                        <div className="flex-center-both flex-col space-y-2">
                            <div className="font-w-bold font-h2 underline">
                                {notifications[0].name}
                            </div>
                            <div className="font-h3">{notifications[0].description}</div>
                        </div>
                        <div className="absolute inset-0 w-full h-full">
                            <div
                                className="w-auto h-full md:w-full opacity-20 relative mr-auto transform "
                                style={{
                                    backgroundImage: "url(" + isWinterComing ? bgWinter : bg + ")",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center top",
                                    backgroundRepeat: "repeat",
                                }}
                            ></div>
                        </div>
                        <button
                            className="absolute left-2 top-2 pt-0.5 flex-center-both rounded-md  bg-butterMilk-200 text-black font-h1 hover-shadow smooth"
                            onClick={() => setIsHidden(true)}
                        >
                            <CenterIcon icon="uil:x" />
                        </button>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default GlobalNotifications;
