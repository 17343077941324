import { useState } from "react";
import Container from "../components/ui/Container";
import HeaderCardsSection from "../components/ui/HeaderCardsSection";
import HeroSection from "../sections/home/MainSection";
import { ProductSection } from "../sections/Products/ProductSection";
import { isMultiProduct } from "../services/defaultSettings";
import InputField from "../components/form/elements/InputField";
import { handleInputChange } from "../services/formServices";
import { productTypes } from "../services/contentServices";

const Products = () => {
    const [filterData, setFilterData] = useState({
        product_type: "all_products",
    });

    return (
        <Container>
            <HeroSection isProducts={true} />

            <div className="flex flex-col items-center justify-center gap-[104px] lg:gap-30">
                <div className="flex flex-col items-center justify-center h-fit gap-10">
                    <HeaderCardsSection
                        title="منتجات"
                        coloredTitle="صلاح ستور"
                        colorClass="text-moGreen-400"
                    />
                    <div className="flex-center-both flex-col sm:flex-row  px-10 py-4 sm:py-8 rounded-2xl space-y-4 sm:space-y-0 sm:gap-10 bg-slate-300 dark:bg-slate-800 smooth ">
                        {isMultiProduct && (
                            <InputField
                                onChange={handleInputChange}
                                data={filterData}
                                setData={setFilterData}
                                type="select"
                                options={[
                                    {
                                        value: "all_products",
                                        text: "جميع المنتجات",
                                    },
                                    ...productTypes,
                                ]}
                                id="product_type"
                                placeholder="أختر نوغ المنتج"
                                // errors={errors}
                            />
                        )}
                    </div>
                </div>
                <ProductSection
                    product_type={filterData.product_type}
                    api={`/api/user/products?product_type=${filterData.product_type}`}
                    isDecoreRight={true}
                    isShowMore={false}
                    limited={false}
                />
                {/* { here are svg decore} */}
            </div>
        </Container>
    );
};

export default Products;
