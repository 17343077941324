import { Disclosure } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/outline";
// import { Icon } from "@iconify/react";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

//context
import AuthContext from "../../context/AuthContext";
import ScrollingContext from "../../context/ScrollingContext";
import ThemeContext from "../../context/ThemeContext";

//ui
import ShowingTransition from "../../components/ui/ShowingTransition";

//navs
import DarkmodeButton from "./components/DarkmodeButton";
import NavbarMenu from "./components/NavbarMenu";
import NavbarMenuItem from "./components/NavbarMenuItem";
import NavbarNotification from "./components/NavbarNotification";
import NavbarRow from "./components/NavbarRow";
import ProgressBar from "./components/ProgressBar";

//navs-assets

// import logo from "../../assets/navbar/logo.png";
import userIcon from "../../assets/navbar/avatar_6996758.png";
import shopIcon from "../../assets/navbar/outletIcon.svg";
import "./navbar.css";
// import CenterIcon from "../../components/ui/CenterIcon";
import {
    adminPath,
    navbarDisclosureColor,
    navbarIconColor,
    outletPath,
} from "../../services/defaultSettings";

import {
    DisclosureLoginComponent,
    DisclosureRegisterComponent,
    navbarClassName,
    NavbarLoginComponent,
    NavbarRegisterComponent,
    navLogoComponent,
} from "../../services/navbarChangableComponents";
import { useCart } from "../../hooks/useCart";
import { Icon } from "@iconify/react";
import { useCartOutlet } from "../../hooks/useCartOutlet";
import auth from "../../services/authServices";
import NavbarLink from "./components/NavbarLink";
import CenterIcon from "../../components/ui/CenterIcon";
import DisclosureLink from "./components/DisclosureLink";
import GlobalNotifications from "./components/GlobalNotifications";
import PrizeNotification from "./components/PrizeNotification";

const Navbar = () => {
    const { totalQuantity } = useCart();
    const { totalQuantityOutlet } = useCartOutlet();

    let { token: authToken, user, outletToken: outletAuth } = useContext(AuthContext);
    const outletToken = auth.getOutletToken();

    let { isRTL, darkmode } = useContext(ThemeContext);
    let { scrolling } = useContext(ScrollingContext);

    const location = useLocation();

    let isNavbarHidden = false;

    if ((location.pathname === "/" || location.pathname === "/home") && scrolling < 1) {
        isNavbarHidden = true;
    }
    const [isAdminPath, setIsAdminPath] = useState(false);
    const [className, setClassName] = useState("");
    useEffect(() => {
        if (location.pathname.includes(adminPath)) {
            setClassName("negative-nav-margin");
            setIsAdminPath(true);
        } else {
            setClassName("");
            setIsAdminPath(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (!location.pathname.includes("questions")) {
            window.scrollTo(0, 0);
        }
    }, [location.pathname]);
    let disclosureColorClassName;
    switch (navbarDisclosureColor) {
        case "rose":
            disclosureColorClassName = ` bg-rose-600 `;
            break;
        case "cyan":
            disclosureColorClassName = ` bg-cyan-600 `;
            break;
        case "emerald":
            disclosureColorClassName = ` bg-emerald-600 `;
            break;
        case "yellow":
            disclosureColorClassName = ` bg-yellow-300 `;
            break;
        case "teal":
            disclosureColorClassName = ` bg-teal-600 `;
            break;
        default:
            disclosureColorClassName = `bg-blue-600`;
            break;
    }

    return (
        <Disclosure
            as="nav"
            className={`${className} navbar z-40 fixed inset-0 bottom-auto smooth ${navbarClassName(
                isNavbarHidden,
                isRTL,
                scrolling
            )}`}
        >
            {({ open }) => (
                <>
                    <GlobalNotifications />
                    <PrizeNotification />

                    <div className="relative h-full">
                        <ProgressBar />
                        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 h-full">
                            <div className="relative flex items-center justify-between h-full flex-row-reverse">
                                {!authToken && !outletToken ? (
                                    <div className="navbar-collapse-button absolute inset-y-0 left-0 flex items-center md:hidden">
                                        {/* Mobile menu button*/}
                                        <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white ar">
                                            <span className="sr-only">Open main menu</span>
                                            <div
                                                id="nav-icon"
                                                className={`${navbarIconColor} ${
                                                    open ? " open" : ""
                                                }`}
                                            >
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </Disclosure.Button>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {/* <DarkmodeButton
                                    className={`block md:hidden absolute ${
                                        authToken ? "left-0" : "right-0"
                                    }`}
                                /> */}
                                <div className="h-10">
                                    <div
                                        className={`absolute ${
                                            authToken || outletToken ? `left-[3%]` : `right-[3%]`
                                        }  block md:hidden `}
                                    >
                                        <NavbarRow>
                                            <Link
                                                to={`${
                                                    !outletToken ? `/cart` : `${outletPath}/cart`
                                                }`}
                                                className="bg-[#133053] relative rounded-full p-2 hover:shadow-xl smooth"
                                            >
                                                <Icon
                                                    className="w-6 h-6 "
                                                    icon="fluent-emoji:shopping-cart"
                                                />
                                                {totalQuantity !== 0 && (
                                                    <span className="bg-red-300 px-1 rounded-full text-black absolute bottom-0 left-0 ">
                                                        {totalQuantity || 0}
                                                    </span>
                                                )}
                                            </Link>
                                        </NavbarRow>
                                    </div>
                                </div>

                                <NavbarRow
                                    isCentered={false}
                                    className="navbar-brand-container flex-1 flex items-center justify-center md:items-center md:justify-end h-full"
                                >
                                    {/* <DarkmodeButton className="hidden md:block" /> */}
                                    <div className="navbar-brand flex-shrink-0 flex items-center">
                                        <Link to="/">{navLogoComponent(darkmode)}</Link>
                                    </div>
                                </NavbarRow>

                                <div
                                    className={`absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:pr-0 sm:ml-6`}
                                >
                                    {!authToken && !outletToken ? (
                                        <div className="md:block hidden">
                                            <NavbarRow>
                                                <NavbarRegisterComponent />
                                                <NavbarLoginComponent isRTL={isRTL} />
                                            </NavbarRow>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {authToken && !outletToken ? (
                                        <>
                                            <NavbarRow isRTL={isRTL} className="space-x-2">
                                                <NavbarMenu
                                                    menuButton={
                                                        <>
                                                            <span className="sr-only">
                                                                Open user menu
                                                            </span>
                                                            <img
                                                                className="h-10 w-10 md:h-12 md:w-12 rounded-full"
                                                                src={userIcon}
                                                                alt=""
                                                            />
                                                        </>
                                                    }
                                                    menuButtonClassName={"rounded-full"}
                                                    menuItems={
                                                        <>
                                                            <NavbarMenuItem
                                                                to="/me/user/"
                                                                text={`اهلًا ${
                                                                    user && user.full_name
                                                                }`}
                                                            />
                                                            <NavbarMenuItem
                                                                icon="carbon:user-avatar-filled"
                                                                // to="profile"
                                                                to="/me/user/"
                                                                text="حسابي"
                                                            />
                                                            <NavbarMenuItem
                                                                icon="icomoon-free:exit"
                                                                to="/logout"
                                                                text="تسجيل خروج"
                                                            />
                                                        </>
                                                    }
                                                />
                                            </NavbarRow>
                                        </>
                                    ) : outletToken ? (
                                        <NavbarRow isRTL={isRTL} className="space-x-2">
                                            <NavbarMenu
                                                menuButton={
                                                    <>
                                                        <span className="sr-only">
                                                            Open user menu
                                                        </span>
                                                        <img
                                                            className="h-10 w-10 md:h-12 md:w-12 rounded-full bg-moGreen-950 border border-solid border-black"
                                                            src={shopIcon}
                                                            alt=""
                                                        />
                                                    </>
                                                }
                                                menuButtonClassName={"rounded-full"}
                                                menuItems={
                                                    <>
                                                        <NavbarMenuItem
                                                            icon="bx:store"
                                                            to={`${outletPath}/outlet/`}
                                                            text="صفحة المنفذ الرئيسية"
                                                        />
                                                        <NavbarMenuItem
                                                            icon="icomoon-free:exit"
                                                            to={`${outletPath}/logout`}
                                                            text="تسجيل خروج"
                                                        />
                                                    </>
                                                }
                                            />
                                        </NavbarRow>
                                    ) : (
                                        <></>
                                    )}

                                    <div className="hidden md:block mx-2">
                                        <NavbarRow>
                                            <Link
                                                to={`${
                                                    !outletToken ? `/cart` : `${outletPath}/cart`
                                                }`}
                                                className="bg-moGreenV2-800 relative rounded-full p-2 hover:shadow-xl smooth"
                                            >
                                                <Icon
                                                    className="w-6 h-6 "
                                                    icon="raphael:cart"
                                                    style={{ color: "#FFF5B7" }}
                                                />
                                                {!outletToken ? (
                                                    <>
                                                        {totalQuantity !== 0 && (
                                                            <span className="bg-red-300 px-1  rounded-full text-black absolute bottom-0 left-0 ">
                                                                {totalQuantity || 0}
                                                            </span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {totalQuantityOutlet !== 0 && (
                                                            <span className="bg-red-300  px-1 rounded-full text-black absolute bottom-0 left-0 ">
                                                                {totalQuantityOutlet || 0}
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                            </Link>
                                            {!authToken && !outletToken ? (
                                                <NavbarLink
                                                    to={`/buyer-register`}
                                                    defualtHover="hover:bg-primary-container hover:text-teal-500 group"
                                                    className="bg-teal-500 clr-white border-2 border-teal-500 group rounded-full"
                                                >
                                                    <span className="flex-center-both py-0.5">
                                                        كن موزع !
                                                    </span>
                                                </NavbarLink>
                                            ) : (
                                                ""
                                            )}
                                        </NavbarRow>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!authToken && !outletToken ? (
                            <ShowingTransition>
                                <Disclosure.Panel
                                    className={`${disclosureColorClassName} text-white rounded-xl mt-1 md:hidden block ar`}
                                >
                                    <div className="px-4 pt-4 pb-5 space-y-4">
                                        <DisclosureRegisterComponent />
                                        <DisclosureLoginComponent />
                                        <DisclosureLink to="/register">
                                            <span className="flex-center-both">كن موزع !</span>
                                        </DisclosureLink>
                                    </div>
                                </Disclosure.Panel>
                            </ShowingTransition>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            )}
        </Disclosure>
    );
};

export default Navbar;
