import React from "react";
import { adminPath } from "../../services/defaultSettings";
import { Link } from "react-router-dom";
import { printIdOfOptions, printUnit } from "../../utils/ar";
import { printFullDateTime } from "../../utils/time";
import Table from "../../components/ui/Table";
import EditableTableCell from "../../components/ui/EditableTableCell";

const OrderPreparationGroupsTable = ({ fetchedData, setFetchedData }) => {
    const groupStatusses = [
        { value: "new", text: "جديد" },
        { value: "opened", text: "تم الفتح" },
        { value: "printed", text: "تم الطباعة" },
        { value: "printed_but_in_progress", text: "تم الطباعة ولكن لم يتم الانتهاء" },
        { value: "prepared", text: "تم التحضير" },
        { value: "completed", text: "تم الانتهاء" },
        { value: "archived", text: "تم ال ارشفة" },
    ];
    const afterSuccessEditing = (valueSubmitted, key, id) => {
        setFetchedData((prev) => {
            let newGroups = [...prev.groups];
            let index = newGroups.findIndex((group) => group.id === id);
            console.log(index);
            newGroups[index][key] = valueSubmitted;
            return { ...prev, groups: newGroups };
        });
    };
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => (
                <Link
                    className="underline"
                    to={`/${adminPath}/panel/order_preparation_groups/${row.id}`}
                >
                    {row.id}
                </Link>
            ),
            sortable: true,
            sortField: "id",
        },
        {
            name: "العنوان",
            reorder: true,
            selector: (row) => (
                <EditableTableCell
                    api={`api/admin/order_preparation_groups/${row.id}`}
                    editableField="name"
                    defaultValue={row.name}
                    afterSuccess={(valueSubmitted) =>
                        afterSuccessEditing(valueSubmitted, "name", row.id)
                    }
                >
                    <Link
                        className="underline"
                        to={`/${adminPath}/panel/order_preparation_groups/${row.id}`}
                    >
                        {row.name}
                    </Link>
                </EditableTableCell>
            ),
            sortable: true,
            sortField: "name",
        },
        {
            name: "ملاحظات",
            reorder: true,
            selector: (row) => (
                <EditableTableCell
                    api={`api/admin/order_preparation_groups/${row.id}`}
                    editableField="note"
                    defaultValue={row.note}
                    afterSuccess={(valueSubmitted) =>
                        afterSuccessEditing(valueSubmitted, "note", row.id)
                    }
                >
                    {row.note ? row.note : "لا يوجد ملاحظات"}
                </EditableTableCell>
            ),
            sortable: true,
            sortField: "note",
        },
        {
            name: "عدد الاوردرات",
            reorder: true,
            selector: (row) => printUnit(row.order_quantity, "اوردر"),
            sortable: true,
            sortField: "order_quantity",
        },
        {
            name: "حالة مجموعة الاوردرات",
            reorder: true,
            selector: (row) => (
                <EditableTableCell
                    api={`api/admin/order_preparation_groups/${row.id}`}
                    editableField="status"
                    inputType="select"
                    options={groupStatusses}
                    defaultValue={row.status}
                    afterSuccess={(valueSubmitted) =>
                        afterSuccessEditing(valueSubmitted, "status", row.id)
                    }
                >
                    {printIdOfOptions(groupStatusses, row.status)}
                </EditableTableCell>
            ),
            sortable: true,
            sortField: "status",
        },
        {
            name: "عدد المنتجات",
            reorder: true,
            selector: (row) => printUnit(row.products_quantity, "منتج"),
            sortable: true,
            sortField: "products_quantity",
        },
        {
            name: "تاريخ انشاء الطلب",
            reorder: true,
            selector: (row) => row.created_at,
            format: (row) => printFullDateTime(row.created_at),
            sortable: true,
            sortField: "created_at",
        },
    ];
    return <Table columns={columns} data={fetchedData.groups} />;
};

export default OrderPreparationGroupsTable;
