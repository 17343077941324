import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import CenterIcon from "../../../components/ui/CenterIcon";
import AuthContext from "../../../context/AuthContext";
import { ReactComponent as SchoolBagIcon } from "../../../assets/school-bag_11772119.svg";
const PrizeNotification = () => {
    const { user } = useContext(AuthContext);

    // List of phone numbers of users who won a prize
    const winnerPhones = [
        "1024655664",
        "1222358694",
        "1003189649",
        "1062513801",
        "1553732624",
        "1158621662",
        "1033050081",
        "1140905330",
        "1550269144",
        "1286043923",
        "1228019486",
        "1022404823",
        "1012187224",
        "1205117573",
        "1126724586",
        "1124907919",
        "1225815279",
        "1113740994",
        "1017463163",
        "1552814423",
        "1033069951",
        "1092497949",
        "1158254593",
        "1281138869",
        "1063528968",
        "1017003246",
        "1272759858",
        "1279075448",
        "1025055410",
        "1554597355",
        "1550166466",
        "1121350331",
        "1144827920",
        "1026333780",
        "1120956366",
        "1023626542",
        "1507422398",
        "1001750042",
        "1116797641",
        "1028805073",
        "1064396344",
        "1225054995",
        "1553257598",
        "1021926882",
        "1097917161",
        "1276776126",
        "1225599988",
        "1030614709",
        "1022933178",
        "1223864195",
        "1556485151",
        "1149099922",
        "1554568295",
        "1117172658",
        "1555265910",
        "1019985674",
        "1030470206",
        "1271177549",
        "1020257969",
        "1274801031",
        "1146252473",
        "1023074709",
        "1099554531",
        "1143144377",
        "1067305748",
        "1555226739",
        "1050385573",
        "1066646327",
        "1003958599",
        "1289705824",
        "1559015744",
        "1146021791",
        "1098123267",
        "1207741132",
        "1021732580",
        "1552234691",
        "1060977335",
        "1098945705",
        "1030491014",
        "1277487678",
        "1271173292",
    ];
    const [isHidden, setIsHidden] = useState(true); // Controls both icon and message visibility
    const [isMessageVisible, setIsMessageVisible] = useState(false); // Controls message visibility
    const [isFirstVisible, setIsFirstVisible] = useState(false); // Controls first-time visibility
    const location = useLocation();

    const isWinner = winnerPhones.includes(user.phone?.toString());

    useEffect(() => {
        if (isWinner) {
            // Show the icon for 3 seconds initially
            setIsFirstVisible(true);
            setIsHidden(false);
            const timer = setTimeout(() => {
                setIsFirstVisible(false); // Hide after 3 seconds
            }, 10000);

            return () => clearTimeout(timer); // Cleanup timer
        }
    }, [isWinner]);

    return (
        <>
            {/* Floating Notification Icon */}
            {!isHidden && (
                <div
                    className={`fixed bottom-4 right-4 z-50 ${
                        isFirstVisible ? "animate-bounce" : ""
                    }`}
                >
                    {/* Icon Button */}
                    <div
                        className="p-3 bg-moGreenV2-600 cursor-pointer rounded-full shadow-lg hover:bg-moGreenV2-700 smooth flex items-center justify-center"
                        onClick={() => {
                            setIsMessageVisible(!isMessageVisible);
                            setIsFirstVisible(false);
                        }}
                    >
                        <SchoolBagIcon className="w-8 h-8" />
                    </div>
                </div>
            )}

            {/* Sliding Message */}
            {isMessageVisible || isFirstVisible ? (
                <div className="fixed bottom-16 right-4 z-49 bg-moGreenV2-800 bg-opacity-90 text-white p-4 rounded-md shadow-lg w-80 animate-slide-up">
                    <div className="relative">
                        <div className="font-bold text-lg text-center underline mb-2">
                            🎉 مبروك يا {user?.full_name} 🎉
                        </div>
                        <p className="text-center">
                            مبروك انت من ضمن الفايزين بشنطة صلاح ستور 🛍️ وهتوصلك في اقرب وقت 🚚
                            <br />
                            يبختك! 😍
                        </p>
                        {/* Close Button */}
                        <button
                            className="absolute -top-3 -right-2 p-1 text-black text-lg bg-moGreenV2-100 rounded-full hover:bg-moGreenV2-600"
                            onClick={() => {
                                setIsHidden(true);
                                setIsFirstVisible(false);
                                setIsMessageVisible(false);
                            }}
                        >
                            <CenterIcon icon="uil:x" />
                        </button>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default PrizeNotification;
