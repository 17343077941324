import { Mugs, Book, HappyFace } from "../../Constants/Svgs";
import { isWinterComing } from "../../services/defaultSettings";
import Button from "./Button";
import { ReactComponent as SnowCardDecore } from "../../assets/events-assets/TopFrameCourseCard.svg";

const CtaCards = ({ colorClass, isBook = true }) => {
    return (
        <div
            className={`${colorClass} relative font-ge shadow-xl min-h-[370px] max-w-[340px] px-5 py-7 rounded-tl-[28px] rounded-br-[28px] rounded-tr-2xl rounded-bl-2xl flex flex-col flex-center-both space-y-10`}
        >
            {isWinterComing && (
                <SnowCardDecore className="absolute z-10 brightness-95 -right-3 -top-2" />
            )}
            <span>{!isBook ? <img src={Mugs} alt="" /> : <img src={Book} alt="" />}</span>
            <div className="flex flex-col flex-center-both space-y-6">
                {!isBook ? (
                    <p className="text-center text-xl">
                        هتلاقي في صلاح ستور مجات لو بتحب تروق علي نفسك وانت بتذاكر
                    </p>
                ) : (
                    <p className="text-center text-xl">
                        كُتب اللغة العربية لمحمد صلاح كلها في مكان واحد يعني مش هتغلب
                    </p>
                )}
                <Button
                    element="Link"
                    color="yellow"
                    className="flex flex-center-both gap-2 w-fit text-black font-bold shadow-md bg-amber-400 border-none hover:scale-105 "
                    to={`${!isBook ? `/products` : `/years`}`}
                >
                    {!isBook ? <p>دلع نفسك</p> : <p>قفل العربي</p>}
                    <img src={HappyFace} alt="" />
                </Button>
            </div>
        </div>
    );
};

export default CtaCards;
