import InputField from "../../components/form/elements/InputField";
import Container from "../../components/ui/Container";
import HeaderCardsSection from "../../components/ui/HeaderCardsSection";
import { isMultiProduct } from "../../services/defaultSettings";
import { ProductSection } from "../Products/ProductSection";
import { useState } from "react";
import { handleInputChange } from "../../services/formServices";
import { productTypes } from "../../services/contentServices";

const MugSection = () => {
    const [filterData, setFilterData] = useState({
        product_type: "all_products",
    });

    // const filteredProductTypes = productTypes.filter((item) => item.value !== "book");
    return (
        <section className="min-h-[80vh] relative overflow-hidden my-10">
            <Container className="h-full relative z-10 font-the-sans pt-32 md:pt-0 pb-20 md:pb-0 space-y-4 flex flex-col items-center justify-center g-[104px] lg:g-[128px]">
                <div className="flex flex-col items-center justify-center gap-[40px] lg:gap-15">
                    <div className="flex flex-col items-center justify-center h-fit gap-10 ">
                        <HeaderCardsSection
                            title="منتجات"
                            coloredTitle="صلاح ستور"
                            colorClass="text-moGreenV2-700"
                        />
                        <div className="flex-center-both flex-col sm:flex-row  px-10 py-4 sm:py-8 rounded-2xl space-y-4 sm:space-y-0 sm:gap-10 bg-slate-300 dark:bg-slate-800 smooth ">
                            {isMultiProduct && (
                                <InputField
                                    onChange={handleInputChange}
                                    data={filterData}
                                    setData={setFilterData}
                                    type="select"
                                    options={[
                                        {
                                            value: "all_products",
                                            text: "جميع المنتجات",
                                        },
                                        ...productTypes,
                                    ]}
                                    id="product_type"
                                    placeholder="أختر نوغ المنتج"
                                    // errors={errors}
                                />
                            )}
                        </div>
                    </div>
                    <ProductSection
                        product_type={filterData.product_type}
                        api={`/api/user/products?product_type=${filterData.product_type}&limit=9`}
                        isDecoreRight={true}
                        isShowMore={true}
                        redirect_link="/products"
                    />
                    {/* { here are svg decore} */}
                </div>
            </Container>
        </section>
    );
};

export default MugSection;
