import React, { useState } from "react";
import auth from "../../services/authServices";
import http from "../../services/httpServices";
import modal from "../../services/modalServices";
import modal2 from "../../services/modal2Services";
import Button from "./Button";

const EditableTableCell = ({
    api,
    inputTitle = "التعديل",
    inputType = "text",
    defaultValue = "",
    afterSuccess = () => null,
    editableField = "",
    options = [],
    children,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmitValue = async (valueSubmitted) => {
        setIsLoading(true);
        try {
            const token = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(token);
            // if (user_key === "father_phone") {
            //     user_value = parseInt(user_value);
            // }
            const dataToSend = { key: editableField, value: valueSubmitted };

            // eslint-disable-next-line no-unused-vars
            const { data } = await http.post(api, dataToSend, config);
            modal.message({
                title: "تم التعديل بنجاح",
                icon: "success",
            });

            afterSuccess(valueSubmitted);
            setIsLoading(false);
        } catch ({ response }) {
            let errors = response.data.errors;
            let error = "";
            for (let key in errors) {
                error += errors[key] + "\n";
            }
            if (error === "") {
                error = response.data.message;
            }

            modal.message({
                title: "حدث خطأ",
                text: error,
                icon: "error",
            });

            setIsLoading(false);
        }
    };
    const showEditableModal = () => {
        let modalOptions = {};
        if (inputType === "select") {
            options.forEach((option) => {
                modalOptions[option.value] = option.text;
            });
        }

        modal2.message({
            title: "هل انت متأكد؟",
            input: inputType,
            inputOptions: modalOptions,
            inputLabel: inputTitle,
            inputValue: defaultValue,
            inputValidator: (value) => {
                if (!value) {
                    return "يجب عليك كتابه القيمة";
                }
            },
            icon: "warning",
            confirmButtonText: "تنفيذ",
            cancelButtonText: "إلغاء",
            showCancelButton: true,
            callback: async (e) => {
                if (e.isConfirmed) {
                    handleSubmitValue(e.value);
                }
            },
        });
    };
    return (
        <span className="flex flex-col flex-center-both space-y-2">
            <span className="inline-block">{children}</span>
            <div>
                <Button
                    color="yellow"
                    className="pb-0.5 px-2"
                    isLoading={isLoading}
                    onClick={(e) => {
                        e.preventDefault();
                        showEditableModal();
                        // handleChangeUserData("full_name", row.full_name);
                    }}
                >
                    تعديل
                </Button>
            </div>
        </span>
    );
};

export default EditableTableCell;
