import { useEffect, useState } from "react";
import AdminContainer from "../../components/ui/AdminContainer";
import AdminForm from "../../components/ui/AdminForm";
import InputIcon from "../../components/form/elements/InputIcon";
import {
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
    handleFormErrors,
} from "../../services/formServices";
import auth from "../../services/authServices";
import http from "../../services/httpServices";
import modal from "../../services/modalServices";
import { printIdOfOptions } from "../../utils/ar";

const initialState = {
    reference_id: "",
    is_order_fetched: 0,
    user_full_name: "",
    user_phone: "",
    total_price: 0,
    total_quantity: 0,
    customer_delivery_price: 0,
    order_type: "rto",
    custom_order_code: 0,
    order_delivery_created: 0,
    comment: "",
};

const ManualOrder = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [governments, setGovernments] = useState([]);
    const [inputFields, setInputFields] = useState([]);

    async function getGovernments() {
        const { data: response } = await http.get("/api/governments");
        setGovernments(response);
        data["government_id"] = response[0]["value"];
        setData(data);
    }
    useEffect(() => {
        getGovernments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data.submit_type && data.submit_type !== "store") {
            // getProducts();
        }
    }, [data.submit_type]);

    const getOrderInfo = async () => {
        setIsLoading(true);
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        try {
            const { data: response } = await http.get(
                `/api/manual_orders/get_ref_order/${data.reference_id}`,
                config
            );
            setData({ ...data, ...response, is_order_fetched: 1 });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            modal.message({
                title: "حدث خطأ",
                text: "",

                icon: "error",
            });
        }
    };

    useEffect(() => {
        let fields = [];
        if (!data.is_order_fetched) {
            fields = [
                ...fields,
                {
                    id: "reference_id",
                    placeholder: "كود الأوردر المرجعي",
                    type: "number",
                    icon: <InputIcon icon="fluent:app-title-24-filled" />,
                    readOnly: data.is_order_fetched,
                },
            ];
        }

        if (data.reference_id > 0 && data.is_order_fetched) {
            fields = [
                ...fields,
                // {
                //     id: "user_full_name",
                //     placeholder: "أسم المستخدم",
                //     type: "number",
                //     icon: <InputIcon icon="fluent:app-title-24-filled" />,
                //     readOnly: true,
                // },
                // {
                //     id: "user_phone",
                //     placeholder: "رقم هاتف المستخدم",
                //     type: "number",
                //     icon: <InputIcon icon="fluent:app-title-24-filled" />,
                //     readOnly: true,
                // },
                // {
                //     id: "total_price",
                //     placeholder: "السعر الكلي",
                //     type: "number",
                //     icon: <InputIcon icon="fluent:app-title-24-filled" />,
                //     readOnly: true,
                // },
                // {
                //     id: "total_quantity",
                //     placeholder: "الكمية الكلية",
                //     type: "number",
                //     icon: <InputIcon icon="fluent:app-title-24-filled" />,
                //     readOnly: true,
                // },
                // {
                //     id: "customer_delivery_price",
                //     placeholder: "سعر التوصيل",
                //     type: "number",
                //     icon: <InputIcon icon="fluent:app-title-24-filled" />,
                //     readOnly: true,
                // },
                // {
                //     id: "government_id",
                //     placeholder: "المحافظة",
                //     type: "select",
                //     options: governments,
                //     icon: <InputIcon icon="fluent:app-title-24-filled" />,
                //     readOnly: true,
                // },
                // {
                //     id: "city",
                //     placeholder: "المدينة",
                //     type: "text",
                //     icon: <InputIcon icon="fluent:app-title-24-filled" />,
                //     readOnly: true,
                // },
                // {
                //     id: "address",
                //     placeholder: "العنوان",
                //     type: "text",
                //     icon: <InputIcon icon="fluent:app-title-24-filled" />,
                //     readOnly: true,
                // },
                // print theese in html
                // user_full_name,
                // user_phone,
                // total_price,
                // total_quantity,
                // customer_delivery_price,
                {
                    id: "order_details",
                    element: "html",
                    input: (
                        <div className="flex-center-both flex-col">
                            <h3 className="font-w-bold font-h3 pb-2">بيانات الأوردر</h3>
                            <p className="flex space-x-2 space-x-reverse ">
                                <span className="font-w-bold">تسلسل الأوردر: </span>
                                <span className="bg-rose-500 px-8 rounded-full font-w-bold text-white ">
                                    {data.reference_id}
                                </span>
                            </p>
                            <p className="flex justify-between w-full border-b-2 border-dashed border-gray-300">
                                <span>اسم المستخدم: </span>
                                <span>{data.user_full_name}</span>
                            </p>
                            <p className="flex justify-between w-full border-b-2 border-dashed border-gray-300">
                                <span>رقم هاتف المستخدم: </span>
                                <span>{data.user_phone}</span>
                            </p>
                            <p className="flex justify-between w-full border-b-2 border-dashed border-gray-300">
                                <span>السعر الكلي: </span>
                                <span>{data.total_price}</span>
                            </p>
                            <p className="flex justify-between w-full border-b-2 border-dashed border-gray-300">
                                <span>الكمية الكلية: </span>
                                <span>{data.total_quantity}</span>
                            </p>
                            <p className="flex justify-between w-full border-b-2 border-dashed border-gray-300">
                                <span>سعر التوصيل: </span>
                                <span>{data.customer_delivery_price}</span>
                            </p>
                            <p className="flex justify-between w-full border-b-2 border-dashed border-gray-300">
                                <span>المحافظة: </span>
                                <span>{printIdOfOptions(governments, data.government_id)}</span>
                            </p>
                            <p className="flex justify-between w-full border-b-2 border-dashed border-gray-300">
                                <span>المدينة: </span>
                                <span>{data.city}</span>
                            </p>
                            <p className="flex justify-between w-full border-b-2 border-dashed border-gray-300">
                                <span>العنوان: </span>
                                <span>{data.address}</span>
                            </p>
                        </div>
                    ),
                },
                {
                    id: "order_type",
                    placeholder: "نوع الأوردر",
                    type: "select",
                    options: [
                        {
                            value: "rto",
                            text: "بدل راجع",
                        },
                        {
                            value: "damaged",
                            text: "شحنة تالفة",
                        },
                        {
                            value: "manual_paid",
                            text: "مدفوعة بالفعل",
                        },
                    ],
                },
                {
                    id: "custom_order_code",
                    placeholder: "كود الأوردر",
                    type: "text",
                    icon: <InputIcon icon="fluent:app-title-24-filled" />,
                },
                {
                    id: "comment",
                    placeholder: "ملاحظة",
                    type: "text",
                    icon: <InputIcon icon="fluent:app-title-24-filled" />,
                },
                {
                    id: "order_delivery_created",
                    placeholder: "تم انشاء الاوردر بالفعل في شركه الشحن؟",
                    type: "switch",
                    icon: <InputIcon icon="fluent:app-title-24-filled" />,
                },
            ];
        }

        setInputFields(fields);
    }, [
        data.address,
        data.city,
        data.customer_delivery_price,
        data.government_id,
        data.is_order_fetched,
        data.reference_id,
        data.total_price,
        data.total_quantity,
        data.user_full_name,
        data.user_phone,
        governments,
    ]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            if (data.is_order_fetched) {
                createManualOrder();
            } else {
                getOrderInfo();
            }
        });
    };
    const createManualOrder = async () => {
        try {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);

            const toSendData = { ...data };
            const { data: response } = await http.post(
                `/api/manual_orders/create_manual_order/${data.reference_id}`,
                toSendData,
                config
            );

            modal.message({
                title: "تم تنفيذ طلبك بنجاح",
                text: response.message,

                callback: () => {
                    window.scrollTo(0, 0);
                },
            });
            setData(initialState);
            setIsLoading(false);
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    return (
        <AdminContainer>
            <AdminForm
                onSubmit={handleSubmit}
                isLoading={isLoading}
                buttonText={data.is_order_fetched ? "انشاء الأوردر" : "بحث"} // Adjust your button text as needed
            >
                {/* Render input fields dynamically */}
                {inputFields.map((input, key) =>
                    renderInputFields(
                        key,
                        input.handleChange ? input.handleChange : handleChange,
                        data,
                        setData,
                        errors,
                        input
                    )
                )}
            </AdminForm>
        </AdminContainer>
    );
};

export default ManualOrder;
