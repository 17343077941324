import { Icon } from "@iconify/react";
import Container from "../components/ui/Container";
import { useCart } from "../hooks/useCart";
import ProductCard from "../components/ui/ProductCard";
import Button from "../components/ui/Button";
import { useContext, useEffect } from "react";
import auth from "../services/authServices";
import http from "../services/httpServices";
import AuthContext from "../context/AuthContext";
import modal from "../services/modalServices";
import { isWinterComing } from "../services/defaultSettings";
import Snowfall from "../components/ui/Snowfall";

export const Cart = () => {
    const { cart, totalPrice, totalQuantity, setCart } = useCart();
    const CheckAvailabilityTotalCart = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const toSendData = { cart };
        const { data } = await http.post(
            `/api/check_and_update_cart_availability`,
            toSendData,
            config
        );
        if (data.unavailableDetected) {
            setCart(data.updated_cart);
            modal.message({
                title: "عفواً!",
                text: data.error_message,
                icon: "warning",
            });
        }
        // console.log(data);
    };
    useEffect(() => {
        // performing Checking cart availbality once user land on cart page
        CheckAvailabilityTotalCart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { user } = useContext(AuthContext);
    let fullAddresEntered = false;

    if (
        user.city &&
        user.address &&
        user.father_phone &&
        user.city.trim() !== "" &&
        user.address.trim() !== ""
    ) {
        fullAddresEntered = true;
    }

    return (
        <>
            {isWinterComing && <Snowfall />}

            <section className=" h-[50vh]  relative overflow-hidden">
                <Container className="h-full flex flex-col justify-center space-y-10 relative z-10 pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="text-center  flex-center-both flex-col space-y-5">
                        <div className="flex-center-both gap-5 text-center h-8">
                            <h1 className="text-moGreen-100 font-w-bold font-h1 dark:text-moGreen-900">
                                عربة التسوق
                            </h1>

                            <div className="bg-moGreen-200 relative rounded-full p-2">
                                <Icon className="w-6 h-6 " icon="fluent-emoji:shopping-cart" />
                                {totalQuantity !== 0 && (
                                    <span className="bg-red-300    px-1 rounded-full text-black absolute bottom-0 left-0 ">
                                        {totalQuantity}
                                    </span>
                                )}
                            </div>
                        </div>
                        {/* {!result.unavailableDetected ? (
                            <></>
                        ) : (
                            <div className="flex flex-col flex-center-both">
                                <h1 className="text-moGreen-100 font-w-bold font-h3 dark:text-moGreen-900">
                                    تم تعديل عربة التسوق لعدم توافر
                                    <br />
                                    <span className="text-secBlue-600 dark:text-secBlue-950">
                                        الكميات المطلوبة من بعض المنتجات
                                    </span>
                                    <br />
                                </h1>
                                <div className="w-full rounded-2xl flex-center-both bg-slate-400 dark:bg-slate-800 shadow-lg ">
                                    {result.unavailableProducts.map((product, index) => (
                                        <div key={index} className="font-griphic flex gap-2">
                                            <span>متوفر من : {product.name}</span>
                                            <span className="font-bold text-moGreen-300 dark:text-moGreen-800">
                                                {product.in_stock}
                                            </span>
                                            قطع
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )} */}
                    </div>
                    {/* Here all Info of total products like costs and quantity */}
                    <div className="flex  flex-center-both font-cairo flex-col">
                        <h2 className="text-goBlue-100 font-semibold font-h2 dark:text-goBlue-700">
                            سعر المنتجات اللي عايزها
                        </h2>
                        <div className="  ">{totalPrice} جنيهًا</div>{" "}
                    </div>

                    {/* Here all products ca rds */}
                </Container>
            </section>
            <Container className="w-full flex flex-center-both flex-col space-y-20">
                <>
                    {cart.length ? (
                        <div className="max-w-7xl flex flex-wrap flex-center-both h-fit gap-4">
                            {cart.map((product, index) => (
                                <ProductCard
                                    key={index}
                                    product={product}
                                    url=""
                                    quantity={product.quantity}
                                    showOperators={true}
                                    showDelete={true}
                                    product_type={product.product_type}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20 bg-rose-200 dark:bg-rose-900 w-1/2 rounded-xl">
                            <span className="flex-center-both flex-col md:flex-row space-y-3 space-x-3 ">
                                <span className="font-h1 flex-center-both text-blue-600 dark:text-blue-400">
                                    <Icon icon="bi:cart-x-fill" />
                                </span>
                                <span className="text-center">
                                    عربة الشراء بتاعتك فاضية أملاها!
                                </span>
                            </span>
                        </div>
                    )}
                </>
                {cart.length ? (
                    fullAddresEntered ? (
                        <div className="">
                            <Button
                                color="emerald"
                                className={`basis-1/2 flex flex-center-both flex-row-reverse gap-2 text-center text-xl rounded-2xl shadow-xl leading-10 `}
                                element="Link"
                                to="/order_invoice"
                            >
                                طلع فاتورة و ادفع دلوقتي
                                <span className="text-moGreen-200">
                                    <Icon icon="fa-solid:file-invoice-dollar" />
                                </span>
                            </Button>
                        </div>
                    ) : (
                        <div className="">
                            <Button
                                color="emerald"
                                className={`basis-1/2 flex flex-center-both flex-row-reverse gap-2 text-center text-xl rounded-2xl shadow-xl leading-10 `}
                                element="Link"
                                to={{
                                    pathname: "/order_invoice",
                                    state: { prevPath: "/order_invoice", from: "/order_invoice" },
                                }}
                            >
                                املا بيانتك دلوقتي عشان تطلع فاتوره !
                                <span className="text-moGreen-200">
                                    <Icon icon="fa-solid:file-invoice-dollar" />
                                </span>
                            </Button>
                        </div>
                    )
                ) : (
                    <div className="">
                        <Button
                            color="purple"
                            className={`basis-1/2 flex flex-center-both flex-row-reverse gap-2 text-center text-xl rounded-2xl shadow-xl leading-10 `}
                            element="Link"
                            to="/bestsellings"
                        >
                            اختار الكتب دلوقتي
                        </Button>
                    </div>
                )}
            </Container>
        </>
    );
};
