import { Billing } from "../../Constants/Svgs";
import Button from "../../components/ui/Button";
import Container from "../../components/ui/Container";
import CtaCards from "../../components/ui/CtaCards";
import Header from "../../components/ui/Header";
import { useAuth } from "../../hooks/useAuth";

const CTA = () => {
    const { token } = useAuth();
    const Cards = [
        {
            colorClass: "bg-butterMilk-50 dark:bg-[#115888]",
            isBook: false,
        },
        {
            colorClass: "bg-butterMilk-100 dark:bg-moGreen-300",
            isBook: true,
        },
    ];
    return (
        <section className="relative overflow-hidden my-10 min-h-screen flex flex-center-both">
            <Container className="h-full flex flex-center-both space-y-10 flex-col relative z-10 pt-32 md:pt-0 pb-20 md:pb-0">
                <h1 className="font-big mx-auto">
                    <span className="font-w-bold relative smooth">
                        <Header title="تفتكر" coloredTitle="ليه" textColor="text-moGreenV2-700" />
                    </span>
                </h1>
                <div className="w-full flex justify-center space-y-14 md:space-y-10 items-center flex-col md:flex-row ">
                    <div className="flex basis-full md:basis-1/2 spcae-y-7 md:space-y-14 justify-center items-center md:items-start px-0 lg:px-12   flex-col">
                        <p className="font-ge text-lg w-full lg:w-2/3  text-center md:text-right md:text-2xl ">
                            عشان أ/محمد صلاح يبسطك اكتر جمعلك كُتب العربي وأي منتج عشان بالك يروق في
                            رحلتك التعليمية .. تفتكر صلاح بسطهالك ولا لسه؟
                        </p>
                        {!token && (
                            <Button
                                className="flex font-ge gap-2 text-xl"
                                color="moGreenV2"
                                element="Link"
                                to="/register"
                            >
                                <span>
                                    <img src={Billing} alt="" />
                                </span>
                                خدلك لفة
                            </Button>
                        )}
                    </div>

                    <div className="flex basis-full md:basis-1/2 flex-col lg:flex-row-reverse flex-center-both gap-5">
                        {Cards.map((card, index) => (
                            <CtaCards
                                key={index}
                                colorClass={card.colorClass}
                                isBook={card.isBook}
                            />
                        ))}
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default CTA;
