import { useContext, useEffect, useState } from "react";
import { AbstractBreakLine, ImageDecore, MinusIcon, PlusIcon } from "../../Constants/Svgs";
import TestCard from "../../assets/testCard.png";
import { Icon } from "@iconify/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCart } from "../../hooks/useCart";
import AuthContext from "../../context/AuthContext";
import modal from "../../services/modalServices";
import { baseURL } from "../../config";
import auth from "../../services/authServices";
import http from "../../services/httpServices";
import LoadingIcon from "./LoadingIcon";
import { description } from "../../utils/ar";
import Button from "./Button";
import CenterIcon from "./CenterIcon";
import { useCartOutlet } from "../../hooks/useCartOutlet";
import { ReactComponent as SnowCardDecore } from "../../assets/events-assets/TopFrameCourseCard.svg";
import { isWinterComing } from "../../services/defaultSettings";

export const PriceSvg = () => (
    <svg
        width="93"
        height="37"
        className="w-[120px] h-auto -mt-3 smooth"
        viewBox="0 0 93 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.95002 25.504C11.4748 26.0092 79.2369 31.2569 84.2395 22.252C89.242 13.2472 16.1004 4.90199 4.94828 12.4968C-1.25379 16.721 -1.05515 23.5022 8.95002 25.504Z"
            fill="#FFDA6B"
            className="fill-[#FFDA6B] dark:fill-[#7d6b33]"
        />
        <path
            d="M10.2229 3.20684C10.2229 3.20684 29.3868 0.352743 45.2892 3.20684"
            stroke="#343A40"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
        />
        <path
            d="M50.8635 34.3984C50.8635 34.3984 82.2543 39.7762 91.5097 26.769"
            stroke="#343A40"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
        />
    </svg>
);
const ProductCard = ({
    product = {},

    product_type,
    isMegaBuyer = false,
    quantity = 0,
    showOperators = true,
    showDelete = true,
    isCartOutlet = false,
    ...props
}) => {
    // Creating context for carts
    const [isLoading, setIsLoading] = useState(false);
    const { cart, setCart, totalPrice, totalQuantity } = useCart();
    const { cartOutlet, setCartOutlet } = useCartOutlet();
    const [productQuantity, setProductQuantity] = useState(product.quantity || 0);
    const [buyerQuantity, setBuyerQuantity] = useState(0);
    const { token, outletToken } = useContext(AuthContext);
    const navigate = useNavigate();
    let location = useLocation();

    const AddToCart = async (quantity, operation) => {
        setIsLoading(true);
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);

        const toSendData = {
            cart: cart,
            total_price: totalPrice,
            total_quantity: totalQuantity,
            current_quantity: quantity,
        };
        try {
            const { data } = await http.post(`/api/add-to-cart/${product.id}`, toSendData, config);
            if (data && data.cart) {
                if (cart.length === 0) {
                    // If the local cart is empty, set it to the data.cart directly
                    setCart(data.cart);
                    modal.message({
                        title: "عملية ناجحة",
                        text: "تمت إضافة المنتج بنجاح إلى السلة",
                        icon: "success",
                    });
                } else {
                    if (operation === "plus") {
                        const existingProductIndex = data.cart.findIndex(
                            (item) => item.id === product.id
                        );

                        if (existingProductIndex !== -1) {
                            const updatedCart = [...data.cart];
                            updatedCart[existingProductIndex].quantity++;
                            setCart(updatedCart);
                        } else {
                            setCart([...data.cart, { ...product, quantity: 1 }]);
                        }
                        setProductQuantity(productQuantity + 1);

                        modal.message({
                            title: "عملية ناجحة",
                            text: "تمت إضافة المنتج بنجاح إلى السلة",
                            icon: "success",
                        });
                    } else {
                        setIsLoading(true);

                        const existingProductIndex = data.cart.findIndex(
                            (item) => item.id === product.id
                        );
                        if (existingProductIndex !== -1) {
                            const updatedCart = [...data.cart];
                            updatedCart[existingProductIndex].quantity--;
                            if (updatedCart[existingProductIndex].quantity === 0) {
                                updatedCart.splice(existingProductIndex, 1);
                            }
                            setCart(updatedCart);
                        }
                        setProductQuantity(productQuantity - 1);
                        modal.message({
                            title: "عملية ناجحة",
                            text: "تمت إزالة المنتج بنجاح من السلة",
                            icon: "success",
                        });
                    }
                }
                setIsLoading(false);
            }
        } catch (error) {
            const message = error.response.data.message;
            modal.message({
                title: "حدث خطأ",
                text: message,
                icon: "error",
            });
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const foundProduct = cart.find((item) => item.id === product.id);
        if (foundProduct) {
            setProductQuantity(foundProduct.quantity);
        } else {
            setProductQuantity(0);
        }
    }, [cart, product.id]);

    const handleIncrement = async (e) => {
        e.preventDefault();
        if (token) {
            await AddToCart(1, "plus");
        } else {
            modal.message({
                title: `يجب عليك تسجيل الدخول اولًا`,
                text: "يرجى تسجيل الدخول لإضافة المنتج إلى السلة",
                icon: "warning",
                button: "تسجيل الدخول",
                callback: (accept) => {
                    if (accept) {
                        navigate("/login", {
                            state: { prevPath: location.pathname },
                        });
                    }
                },
            });
        }
    };

    const handleDecrement = async (e) => {
        e.preventDefault();
        if (token && productQuantity > 0) {
            await AddToCart(-1, "minus");

            // AddToCart();
        } else if (productQuantity === 0) {
            modal.message({
                title: ` مينفعش اقل من كده!`,
                text: "مينفعش الكمية تبقي اقل من  1",

                icon: "error",
                button: "قشطة",
            });
        } else {
            modal.message({
                title: `يجب عليك تسجيل الدخول اولًا`,
                text: "يرجى تسجيل الدخول لإزالة المنتج من السلة",

                icon: "warning",
                button: "تسجيل الدخول",
                callback: (accept) => {
                    if (accept) {
                        navigate("/login", {
                            state: { prevPath: location.pathname },
                        });
                    }
                },
            });
        }
    };

    const handleDeletion = (e) => {
        e.preventDefault();
        if (token || outletToken) {
            if (!isCartOutlet) {
                const productIndex = cart.findIndex((item) => item.id === product.id);

                if (productIndex !== -1) {
                    const updatedCart = cart.filter((item) => item.id !== product.id);
                    setCart(updatedCart);
                    modal.message({
                        title: "عملية ناجحة",
                        text: "تمت إزالة المنتج بنجاح من السلة",
                        icon: "success",
                    });
                }
            } else {
                const productIndex = cartOutlet.findIndex((item) => item.id === product.id);

                if (productIndex !== -1) {
                    const updatedCart = cartOutlet.filter((item) => item.id !== product.id);
                    setCartOutlet(updatedCart);
                    modal.message({
                        title: "عملية ناجحة",
                        text: "تمت إزالة المنتج بنجاح من السلة",
                        icon: "success",
                    });
                }
            }
        }
    };

    const handleAddToOrder = () => {
        const quantityToAdd = parseInt(buyerQuantity);
        if (quantityToAdd > 0) {
            const updatedCart = [...cartOutlet];
            const existingProductIndex = updatedCart.findIndex((item) => item.id === product.id);
            if (existingProductIndex !== -1) {
                updatedCart[existingProductIndex].quantity += quantityToAdd;
            } else {
                updatedCart.push({ ...product, quantity: quantityToAdd });
            }
            setCartOutlet(updatedCart);
            modal.message({
                title: "عملية ناجحة",
                text: "تمت إضافة الطلب بنجاح إلى السلة",
                icon: "success",
            });
            setBuyerQuantity(0);
        } else {
            modal.message({
                title: "خطأ",
                text: "الرجاء إدخال كمية صحيحة لإضافتها إلى السلة",
                icon: "error",
            });
        }
    };
    const handleRemoveFromOrder = () => {
        const quantityToRemove = parseInt(buyerQuantity);
        if (quantityToRemove > 0) {
            const updatedCart = [...cartOutlet];
            const existingProductIndex = updatedCart.findIndex((item) => item.id === product.id);
            if (existingProductIndex !== -1) {
                if (updatedCart[existingProductIndex].quantity > quantityToRemove) {
                    updatedCart[existingProductIndex].quantity -= quantityToRemove;
                } else {
                    updatedCart.splice(existingProductIndex, 1);
                }
                setCartOutlet(updatedCart);
                modal.message({
                    title: "عملية ناجحة",
                    text: "تمت إزالة الطلب بنجاح من السلة",
                    icon: "success",
                });
                // setBuyerQuantity(0);
            } else {
                modal.message({
                    title: "خطأ",
                    text: "المنتج غير موجود في السلة",
                    icon: "error",
                });
            }
        } else {
            modal.message({
                title: "خطأ",
                text: "الرجاء إدخال كمية صحيحة لإزالتها من السلة",
                icon: "error",
            });
        }
    };
    return (
        <>
            <Link to={!isMegaBuyer ? `/product/${product.id} ` : ``}>
                <div
                    className={`flex flex-col relative max-w-[300px] flex-center-both space-y-4 h-fit min-h-[550px] smooth cursor-pointer shadow-md rounded-[22px] py-3 px-6 ${
                        product.year == 4
                            ? `bg-moGreenV2-50 dark:bg-[#1A4C4C]`
                            : `bg-butterMilk-50 dark:bg-[#3D5A5B]`
                    } `}
                >
                    {isWinterComing && (
                        <SnowCardDecore className="absolute z-10 brightness-95 -right-3 -top-2" />
                    )}

                    <div className=" flex flex-center-both">
                        <div className="rounded-[62px]  w-[250px] h-[250px] flex flex-center-both relative">
                            <div className="w-full h-full absolute inset-0  ">
                                <div className="w-full h-full  absolute inset-0 rounded-[44px] border-2 border-solid border-[#857371]" />
                                <div className="flex-center-both h-full rounded-[44px]">
                                    <img
                                        className="w-[90%] rounded-[44px] object-cover relative "
                                        src={
                                            product.picture
                                                ? `${baseURL}/${product.picture}`
                                                : `${TestCard}`
                                        }
                                        style={{
                                            aspectRatio: "1/1",
                                        }}
                                        alt={`thumbnail for ${product.name}`}
                                    />
                                </div>
                                <img
                                    src={ImageDecore}
                                    alt="decore"
                                    className="w-full absolute top-0 z-10"
                                    style={{ aspectRatio: "1/1" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-center-both space-y-4 w-full">
                        <div className="space-y-2">
                            {product.is_above_5_free_order_delivery ? (
                                <div class="w-100 font-w-bold font-small text-center bg-teal-600 text-white px-5 pt-1 pb-1.5 rounded-lg">
                                    شحن مجاني على الاوردر لو طلبت خمس كتب أو أكتر من الكتاب ده !
                                </div>
                            ) : (
                                ""
                            )}
                            <h1 className="text-center text-moGreenV2-800 font-w-bold    text-2xl">
                                {product.name}
                            </h1>
                        </div>
                        <div className="flex flex-col flex-center-both space-y-4">
                            <img src={AbstractBreakLine} alt="AbstractBreakLine" />
                            <p className="text-center dark:text-gray-300 font-Noto">
                                {description(product.description, true, "", 30)}
                            </p>
                            <img src={AbstractBreakLine} alt="AbstractBreakLine" />
                        </div>
                        <div className="flex items-center justify-between w-full">
                            {!product.in_stock ? (
                                <>
                                    <div className="col-12 px-2">
                                        {product.product_type !== "book" ? (
                                            <div className="py-1 px-3 w-100 bg-rose-900 clr-white my-3 text-center rounded-lg">
                                                المنتج ده خلص للأسف , هيتوفر منه كمية جديدة قريب !
                                            </div>
                                        ) : (
                                            <div className="py-1 px-3 w-100 bg-rose-900 clr-white my-3 text-center rounded-lg">
                                                الكتاب ده خلص للأسف , هيتوفر منه كمية جديدة قريب !
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : !isMegaBuyer ? (
                                showOperators && (
                                    <>
                                        <div
                                            className={`flex items-center justify-start gap-2 h-full w-full  rounded-md select-none `}
                                        >
                                            <div
                                                className="product-card__operator  text-white h-full py-2 "
                                                onClick={handleIncrement}
                                            >
                                                {isLoading ? (
                                                    <div className="flex-center-both">
                                                        <Icon
                                                            icon="eos-icons:bubble-loading"
                                                            color="#a684f5"
                                                        />
                                                    </div>
                                                ) : (
                                                    <img
                                                        src={PlusIcon}
                                                        alt="PlusIcon"
                                                        className="w-[24px] h-[40px]"
                                                    />
                                                )}
                                            </div>
                                            <div
                                                className={`bg-goBlue-800 border-[1px] smooth border-solid rounded border-goBlue-100 font-cairo font-w-bold shadow-lg
                                    ${
                                        !product.in_stock || productQuantity === 0
                                            ? "opacity-50"
                                            : ""
                                    }
                                    text-black h-fit flex items-center justify-center px-4`}
                                            >
                                                {productQuantity}
                                            </div>

                                            <div
                                                className={`product-card__operator 
                                        ${
                                            !product.in_stock || productQuantity === 0
                                                ? "opacity-20 cursor-not-allowed"
                                                : ""
                                        }
                                        text-white h-full py-2 `}
                                                onClick={handleDecrement}
                                            >
                                                {isLoading ? (
                                                    <div className="flex-center-both">
                                                        <Icon
                                                            icon="eos-icons:bubble-loading"
                                                            color="#a684f5"
                                                        />
                                                    </div>
                                                ) : (
                                                    <img
                                                        src={MinusIcon}
                                                        alt="PlusIcon"
                                                        className=" w-[24px] h-[40px]"
                                                    />
                                                )}

                                                {/* <Icon icon="ic:round-minus" /> */}
                                            </div>
                                        </div>
                                        <div className="relative    w-full h-fit">
                                            <PriceSvg />
                                            <div className="mozkra-card__price flex space-x-4 absolute inset-0 text-center">
                                                <div className="text"> السعر:</div>

                                                <div className="font-bold font-Noto flex space-x-2">
                                                    <span>{product.price}</span>
                                                    <span className="">جنيهًا</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            ) : (
                                <div className="flex flex-col space-y-5">
                                    <div className="flex-center-both gap-2">
                                        <div className="flex flex-col">
                                            <p>
                                                {" "}
                                                {!isCartOutlet
                                                    ? ":ادخل الكمية"
                                                    : "الكمية اللي طلبتها"}
                                            </p>
                                            <div
                                                className={`bg-goBlue-800 border-[1px] smooth border-solid rounded border-goBlue-100 font-cairo font-w-bold shadow-lg text-black h-fit flex items-center justify-center px-4`}
                                            >
                                                {!isCartOutlet ? (
                                                    <input
                                                        type="number"
                                                        value={buyerQuantity}
                                                        onChange={(e) =>
                                                            setBuyerQuantity(e.target.value)
                                                        }
                                                        onWheel={(e) => e.preventDefault()}
                                                        min="0"
                                                        max="5000"
                                                        className="bg-transparent w-full border-none outline-none text-center text-black font-cairo font-w-bold"
                                                    />
                                                ) : (
                                                    <span>
                                                        {cartOutlet.find(
                                                            (item) => item.id === product.id
                                                        )?.quantity || 0}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="relative h-fit">
                                            <PriceSvg />
                                            <div className="mozkra-card__price flex space-x-4 absolute inset-0 text-center">
                                                <div className="text"> السعر:</div>
                                                <div className="font-bold font-Noto flex space-x-2">
                                                    <span>{product.price}</span>
                                                    <span className="">جنيهًا</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {!isCartOutlet ? (
                                        <div className="flex gap-2 flex-center-both">
                                            <Button
                                                onClick={handleAddToOrder}
                                                className="bg-moGreen-600 shadow-md flex-center-both gap-1 px-3 py-2 border-none"
                                            >
                                                إضافة طلب
                                                <CenterIcon icon="ph:plus-bold" />
                                            </Button>
                                            <Button
                                                onClick={handleRemoveFromOrder}
                                                className="bg-[#F57133] shadow-md px-3 py-2 flex-center-both gap-1 border-none"
                                            >
                                                إزالة الطلب
                                                <CenterIcon icon="ph:minus-bold" />
                                            </Button>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            )}
                        </div>
                        {showDelete && (
                            <div
                                onClick={handleDeletion}
                                className="product-card__delete w-full bg-rose-dark-1 bg-red-800 text-center text-white rounded-full smooth flex-center-both pointer"
                            >
                                <div className="font-h1 py-2 space-x-6 flex flex-center-both">
                                    <span className="text-lg w-full font-Noto ">عايز تمسحني ؟</span>
                                    <Icon className="" icon="fluent:delete-lines-20-filled" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Link>
        </>
    );
};

export default ProductCard;
